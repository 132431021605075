/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.delivery-details-drawer__preloader {
  text-align: center;
  padding: 100px 0; }

.delivery-details-drawer__content {
  background: #F6F6F6; }

.delivery-details-drawer__header {
  display: grid;
  grid-template-columns: repeat(4, 1fr); }

.delivery-details-drawer__table__quantity, .delivery-details-drawer__table__quantity-header {
  width: 50px;
  text-align: center !important; }

.delivery-details-drawer__table__price, .delivery-details-drawer__table__price-header {
  width: 75px;
  text-align: right !important; }

.delivery-details-drawer__footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 25px; }
