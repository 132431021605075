.ticket-lookup {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; }
  .ticket-lookup h6 {
    margin-bottom: 10px; }
  .ticket-lookup__form {
    display: flex;
    flex-direction: column;
    min-width: 400px; }
  .ticket-lookup__note {
    margin-bottom: 15px; }

.default-ticket-select {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #CCC; }
