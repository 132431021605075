/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.drawer {
  position: fixed;
  z-index: 100;
  display: flex;
  flex-direction: column;
  width: inherit;
  max-height: 70vh;
  min-height: 25vh;
  box-shadow: 0 -2px 5px 0 rgba(0, 0, 0, 0.1);
  background: #FFF;
  top: 100%;
  transition: transform .250s; }
  .drawer--open {
    transform: translateY(-100%); }
  .drawer__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background: #FFF;
    padding-left: 2px !important; }
    .drawer__header-close {
      padding: 0 10px !important; }
      .drawer__header-close-icon svg {
        height: 22px;
        width: 22px; }
        .drawer__header-close-icon svg polygon {
          fill: #0070EB; }
          .drawer__header-close-icon svg polygon:hover {
            fill: #0058B8; }
    .drawer__header-title {
      margin-bottom: 0px; }
  .drawer__container {
    position: absolute;
    width: inherit;
    height: 100%; }
  .drawer__content {
    overflow-y: auto;
    overflow-x: hidden; }
