/**
 * SPACE & GUTTERS
 **/
/**
 *
 * @param $level
 *
 * Use this function to calculate any spacing value on the spacing scale.
 *
  * Examples:
 * get-spacing-value(5) => 20px
 **/
/**
 * make-inset()
 * make-squished-inset()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-inset(3) === reassign-inset-3
 **/
/**
 * make-section()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-section(3) === reassign-section-3
 **/
/**
 * make-static-gutter()
 * @param $level
 *
 * Get a gutter based on a level
 * @include make-gutter(1) === .reassign-gutter-1
 **/
/**
 * make-responsive-gutter()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Make a responsive gutter
 * @include make-responsive-gutter(3, 'sm') === reassign-gutter-sm-3
 **/
/**
 * make-stack-x|y()
 * @param $level
 *
 * Adds margins based on stack levels
 * @include make-stack-y(1) === .reassign-stack-y-1
 **/
/**
 * make-stack-cancel-up|down-x|y()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Cancels stack by breakpoint up or down, on X or Y axis
 * @include make-stack-cancel-down-y('xs') === reassign-stack-cancel-xs
 **/
/*
 * Initialize the SPACING_MAP from the SPACING_LEVELS
 * the SPACING_MAP includes levels and values on the
 * a modular spacing scale.
 **/
/**
 * get-spacing-value: Function
 * get-gutter-value: Function
 *
 * @param $level
 *
 * This function takes a level from $SPACING_LEVELS / $SPACING MAP
 * and returns the pixel value of that level accordingly.
 *
 * Optionally include a modifier, which adjusts the level
 *
 * Examples:
 * get-spacing-value(6) => 24px
 * get-spacing-value(12, $modifier: -2) => 24px
 * get-gutter-value(1) => 4px
 **/
.scheduling-blocks__time {
  display: block;
  color: #008280; }

.scheduling-blocks__spacer {
  display: block;
  height: 1px;
  width: 100%; }

.scheduling-blocks__region {
  margin-bottom: 8px; }
