.driver-search {
  position: relative; }
  .driver-search__backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0); }
  .driver-search__suggested-drivers {
    width: 425px;
    height: 120px;
    position: absolute;
    top: 48px;
    left: 0;
    z-index: 10;
    overflow: auto;
    background: white;
    border: 2px solid rgba(0, 0, 0, 0.2);
    border-top: none;
    border-radius: 0px 0px 4px 4px; }
