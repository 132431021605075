.orders-map {
  background-color: #0e0e0e; }
  .orders-map__rotation-control {
    top: 92px !important; }
  .orders-map__zoom-control {
    top: 32px !important; }
  .orders-map__show-couriers {
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 10;
    margin: 16px; }
    .orders-map__show-couriers .checkbox__title {
      color: #FFF; }
  .orders-map .mapboxgl-ctrl-top-right {
    top: 115px;
    right: 1px; }
    .orders-map .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group {
      border-radius: 2px; }
      .orders-map .mapboxgl-ctrl-top-right .mapboxgl-ctrl-group > button {
        width: 26px;
        height: 26px; }
