/**
 * Dimension - Mixins
 *
 * The following mixin is concerned only with helping apply shadows.
 *
 * Usage:
 *
 * box-shadow(2) <- applies a bottom directioned shadow
 * box-shadow(2, left) <- applies a left directioned shadow
 * box-shadow-reinforced(1, top) <- applies shadow with a reinforcing border.
 *
 **/
/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Lines - Variables
 *
 * This file defines line variables
 * used in components and utilities,
 * including horizontal rules and borders.
 *
 * 1) Line styles (sass map)
 * 2) Line getter function (get sass map values)
 *
 * See components/_line for line component classes.
 * See utilities/_borders for border utilities.
 *
 **/
/*------------------------------------------------------
 *
 * 1) Line styles sass map
 *
 *-----------------------------------------------------*/
/**
 * $Lines: sass-map
 *
 * This is a map of line styles
 * grouped by modification.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Line getter function
 *
 *-----------------------------------------------------*/
/**
 * get-line: Function
 *
 * @param $type
 * @param $variation (optional)
 *
 * This function takes a line style variation type from $Lines
 * and returns the appropriate value.
 * If no variation parameter is provided, the function
 * will return the base value.
 *
 * ie. get-line-width(thick) => 3px
 *     get-line-color(dark) => RGBA(...)
 **/
/**
 * SPACE & GUTTERS
 **/
/**
 *
 * @param $level
 *
 * Use this function to calculate any spacing value on the spacing scale.
 *
  * Examples:
 * get-spacing-value(5) => 20px
 **/
/**
 * make-inset()
 * make-squished-inset()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-inset(3) === reassign-inset-3
 **/
/**
 * make-section()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-section(3) === reassign-section-3
 **/
/**
 * make-static-gutter()
 * @param $level
 *
 * Get a gutter based on a level
 * @include make-gutter(1) === .reassign-gutter-1
 **/
/**
 * make-responsive-gutter()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Make a responsive gutter
 * @include make-responsive-gutter(3, 'sm') === reassign-gutter-sm-3
 **/
/**
 * make-stack-x|y()
 * @param $level
 *
 * Adds margins based on stack levels
 * @include make-stack-y(1) === .reassign-stack-y-1
 **/
/**
 * make-stack-cancel-up|down-x|y()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Cancels stack by breakpoint up or down, on X or Y axis
 * @include make-stack-cancel-down-y('xs') === reassign-stack-cancel-xs
 **/
/*
 * Initialize the SPACING_MAP from the SPACING_LEVELS
 * the SPACING_MAP includes levels and values on the
 * a modular spacing scale.
 **/
/**
 * get-spacing-value: Function
 * get-gutter-value: Function
 *
 * @param $level
 *
 * This function takes a level from $SPACING_LEVELS / $SPACING MAP
 * and returns the pixel value of that level accordingly.
 *
 * Optionally include a modifier, which adjusts the level
 *
 * Examples:
 * get-spacing-value(6) => 24px
 * get-spacing-value(12, $modifier: -2) => 24px
 * get-gutter-value(1) => 4px
 **/
.popper, .tooltip {
  position: absolute;
  z-index: 2;
  background: #FFF;
  border-radius: 3px;
  box-shadow: get-shadow(1, );
  border: 1px solid rgba(0, 0, 0, 0.2); }
  .popper .popper__arrow, .popper .tooltip-arrow, .tooltip .popper__arrow, .tooltip .tooltip-arrow {
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: #FFF;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    position: absolute;
    margin: 5px; }
  .popper .tooltip-inner, .tooltip .tooltip-inner {
    padding: 16px; }

.popper[x-placement^="top"], .tooltip[x-placement^="top"] {
  margin-bottom: 5px; }
  .popper[x-placement^="top"] .popper__arrow, .popper[x-placement^="top"] .tooltip-arrow, .tooltip[x-placement^="top"] .popper__arrow, .tooltip[x-placement^="top"] .tooltip-arrow {
    bottom: -6px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0; }

.popper[x-placement^="bottom"], .tooltip[x-placement^="bottom"] {
  margin-top: 5px; }
  .popper[x-placement^="bottom"] .popper__arrow, .popper[x-placement^="bottom"] .tooltip-arrow, .tooltip[x-placement^="bottom"] .popper__arrow, .tooltip[x-placement^="bottom"] .tooltip-arrow {
    top: -6px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
    transform: rotate(225deg); }

.popper[x-placement^="right"], .tooltip[x-placement^="right"] {
  margin-left: 5px; }
  .popper[x-placement^="right"] .popper__arrow, .popper[x-placement^="right"] .tooltip-arrow, .tooltip[x-placement^="right"] .popper__arrow, .tooltip[x-placement^="right"] .tooltip-arrow {
    left: -6px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
    transform: rotate(135deg); }

.popper[x-placement^="left"], .tooltip[x-placement^="left"] {
  margin-right: 5px; }
  .popper[x-placement^="left"] .popper__arrow, .popper[x-placement^="left"] .tooltip-arrow, .tooltip[x-placement^="left"] .popper__arrow, .tooltip[x-placement^="left"] .tooltip-arrow {
    right: -6px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
    transform: rotate(310deg); }
