/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Text - Variables
 *
 * This file defines text variables
 * used in components and utilities.
 *
 **/
/**
 * get-text-color: Function
 *
 **/
.weather-widget {
  position: relative; }
  .weather-widget__error {
    color: #FF9100; }
  .weather-widget__button {
    display: flex;
    align-items: center;
    height: 32px;
    margin: 0;
    padding: 0 10px;
    background: #FFF;
    border: 1px solid #FFF;
    border-radius: 27px;
    font-size: 14px; }
    .weather-widget__button:hover {
      border: 1px solid #4E4E52; }
    .weather-widget__button__img {
      width: 32px;
      height: 32px; }
    .weather-widget__button__description {
      font-size: 14px;
      line-height: 32px; }
  .weather-widget__extra {
    position: absolute;
    top: 100%;
    left: 0;
    padding: 15px;
    white-space: pre;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5); }
    .weather-widget__extra > strong {
      margin-right: 5px;
      color: rgba(0, 0, 0, 0.55);
      font-weight: normal; }
      .weather-widget__extra > strong:not(:first-of-type) {
        margin-left: 12px; }
