/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.resizable-container {
  display: flex; }
  .resizable-container__handle {
    width: 8px;
    height: 100%;
    background: #CACACA;
    border-left: 1px solid #FFF;
    border-right: 1px solid rgba(0, 0, 0, 0.35);
    cursor: ew-resize;
    flex: 0 0 auto; }
  .resizable-container__box {
    overflow: hidden;
    box-sizing: border-box;
    flex: 1 1 auto; }
    .resizable-container__box--dragging * {
      user-select: none; }
