/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * SPACE & GUTTERS
 **/
/**
 *
 * @param $level
 *
 * Use this function to calculate any spacing value on the spacing scale.
 *
  * Examples:
 * get-spacing-value(5) => 20px
 **/
/**
 * make-inset()
 * make-squished-inset()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-inset(3) === reassign-inset-3
 **/
/**
 * make-section()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-section(3) === reassign-section-3
 **/
/**
 * make-static-gutter()
 * @param $level
 *
 * Get a gutter based on a level
 * @include make-gutter(1) === .reassign-gutter-1
 **/
/**
 * make-responsive-gutter()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Make a responsive gutter
 * @include make-responsive-gutter(3, 'sm') === reassign-gutter-sm-3
 **/
/**
 * make-stack-x|y()
 * @param $level
 *
 * Adds margins based on stack levels
 * @include make-stack-y(1) === .reassign-stack-y-1
 **/
/**
 * make-stack-cancel-up|down-x|y()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Cancels stack by breakpoint up or down, on X or Y axis
 * @include make-stack-cancel-down-y('xs') === reassign-stack-cancel-xs
 **/
/*
 * Initialize the SPACING_MAP from the SPACING_LEVELS
 * the SPACING_MAP includes levels and values on the
 * a modular spacing scale.
 **/
/**
 * get-spacing-value: Function
 * get-gutter-value: Function
 *
 * @param $level
 *
 * This function takes a level from $SPACING_LEVELS / $SPACING MAP
 * and returns the pixel value of that level accordingly.
 *
 * Optionally include a modifier, which adjusts the level
 *
 * Examples:
 * get-spacing-value(6) => 24px
 * get-spacing-value(12, $modifier: -2) => 24px
 * get-gutter-value(1) => 4px
 **/
.label-tooltip {
  display: flex; }
  .label-tooltip__label {
    margin-right: 4px; }
  .label-tooltip__icon #iconinfo-info-path {
    fill: #4E4E52; }
  .label-tooltip .tooltip {
    max-width: 284px;
    font-size: 14px;
    z-index: 1; }
    .label-tooltip .tooltip .tooltip-inner {
      padding: 16px 24px; }
