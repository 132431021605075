/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.offer-pane {
  border-radius: 0 0 3px 3px;
  background-color: #F6F6F6;
  display: grid;
  grid-template-columns: 9fr 3fr;
  grid-template-rows: 1fr min-content; }
  .offer-pane__content {
    display: grid;
    grid-template-columns: 3fr 3fr 3fr; }
  .offer-pane__value .details-field__label {
    color: rgba(0, 0, 0, 0.55); }
  .offer-pane__actions {
    align-self: center; }
    .offer-pane__actions--send-offer {
      width: 100%;
      margin-right: 5px;
      font-size: 15px;
      font-family: grubhubsans-bold;
      cursor: pointer; }
      .offer-pane__actions--send-offer-disabled {
        cursor: not-allowed;
        color: #CACACA; }
    .offer-pane__actions--close:hover {
      cursor: pointer; }
  .offer-pane__footer {
    padding-top: 10px;
    display: flex;
    justify-content: flex-end;
    grid-column: 1 / -1;
    align-items: center; }
  .offer-pane__driver-toggle-text {
    color: rgba(0, 0, 0, 0.55);
    margin-right: 5px; }
