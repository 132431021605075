.remaining-snooze-tooltip {
  flex-shrink: 0; }
  .remaining-snooze-tooltip__flaw {
    margin: 10px 0; }
  .remaining-snooze-tooltip__icon {
    position: relative;
    bottom: 1px;
    left: 5px; }
    .remaining-snooze-tooltip__icon #iconinfo-info-path {
      fill: #4C4C4C; }
