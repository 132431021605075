/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.driver-pin {
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .driver-pin:hover {
    cursor: pointer; }
  .driver-pin__transport {
    position: absolute;
    top: 30px;
    color: #fff; }
    .driver-pin__transport img {
      margin: 0; }
    .driver-pin__transport--selected {
      top: 35px; }
      .driver-pin__transport--selected-scooter {
        top: 35px;
        left: 20px; }
  .driver-pin--notSelected {
    opacity: .7;
    trasition: opacity 0.1s ease-in; }
  .driver-pin__icon-wrapper {
    position: relative; }
  .driver-pin__icon {
    position: absolute;
    top: 3px;
    left: -6px; }
    .driver-pin__icon--reaped path {
      fill: #F8C448; }
    .driver-pin__icon--reaped polygon {
      fill: #333; }
  .driver-pin__label-icon {
    margin-left: 5px;
    padding-top: 1px; }
