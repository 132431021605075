.sign-in {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: flex-start;
  justify-content: center; }

.sign-in__card {
  min-width: 400px;
  margin-top: 100px;
  padding: 20px; }

.sign-in__title {
  margin: 0 0 16px; }

.sign-in__error {
  color: red;
  margin-top: 0; }
