/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.ticket-details {
  height: 100%;
  overflow: visible;
  display: flex;
  flex: 1; }
  .ticket-details > * {
    flex: 1; }
  .ticket-details .reassign-inset-4:empty {
    display: none; }
  .ticket-details__map-container {
    min-width: 400px;
    height: 100%;
    min-height: 100vh;
    flex-grow: 1; }
  .ticket-details__details {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    min-height: 100vh;
    width: inherit;
    overflow: auto;
    background: #EFEFEF; }
  .ticket-details__wrapper {
    padding: 20px 0 16px 0; }
