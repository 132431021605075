.pin-icon--green use {
  fill: #00AB11; }

.pin-icon--green-selected path {
  fill: #A4E2B2; }

.pin-icon--green-selected path:last-child {
  fill: #008E15; }

.pin-icon--orange use {
  fill: #FF9100; }

.pin-icon--orange-selected path {
  fill: #FFD7A7; }

.pin-icon--orange-selected path:last-child {
  fill: #FA8600; }

.pin-icon--red use {
  fill: #ED0D36; }

.pin-icon--red-selected path {
  fill: #FFB3BC; }

.pin-icon--red-selected path:last-child {
  fill: #CE0B2E; }

.pin-icon--yellow use {
  fill: #FFCE57; }

.pin-icon--yellow-selected path {
  fill: #FFEDC3; }

.pin-icon--yellow-selected path:last-child {
  fill: #F8C448; }

.pin-icon--red use {
  fill: #ED0D36; }

.pin-icon--red-selected path {
  fill: #FFB3BC; }

.pin-icon--red-selected path:last-child {
  fill: #CE0B2E; }

.pin-icon--violet use {
  fill: #9168E1; }

.pin-icon--violet-selected path {
  fill: #E4D0FF; }

.pin-icon--violet-selected path:last-child {
  fill: #7147C8; }
