.trip-details-list {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important; }
  .trip-details-list__droppable--active {
    box-shadow: inset 0 0 0 2px #008280; }
  .trip-details-list .fade-enter {
    opacity: 0.01; }
  .trip-details-list .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in; }
