.recommended-drivers-list-header {
  display: flex;
  align-items: baseline; }
  .recommended-drivers-list-header__arrivals {
    flex: 1; }
  .recommended-drivers-list-header__icon {
    position: relative;
    top: 3px;
    font-size: 20px;
    color: #e96b2c; }
