/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.checkbox {
  display: flex;
  align-items: center; }
  .checkbox__input {
    position: absolute;
    top: -99999px;
    left: -99999px; }
  .checkbox__box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    background: #E5E5E5;
    border: 1px solid #C9C9C9; }
    .checkbox__box__icon {
      display: none; }
      .checkbox__box__icon #Feedback-Modal {
        fill: #FFF; }
      .checkbox__box__icon .icon__icon {
        margin: 0; }
  .checkbox__title {
    margin-left: 8px; }
  .checkbox:not(.checkbox--disabled) {
    cursor: pointer; }
  .checkbox--checked .checkbox__box {
    background: #1686C3;
    border: 1px solid #1686C3; }
    .checkbox--checked .checkbox__box__icon {
      display: block; }
  .checkbox--disabled {
    opacity: 0.5; }
