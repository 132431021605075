.trip-details-row-confirmation-status__icon {
  position: relative;
  top: -1px; }
  .trip-details-row-confirmation-status__icon--refresh #Combined-Shape {
    fill: #0070EB; }
    .trip-details-row-confirmation-status__icon--refresh #Combined-Shape #Combined-Shape {
      fill: #F63440; }
    .trip-details-row-confirmation-status__icon--refresh #Combined-Shape #iconclockback-boundingbox2 {
      fill: #FFFFFF; }
  .trip-details-row-confirmation-status__icon--clock #iconclock-boundingbox {
    fill: #FFFFFF; }
  .trip-details-row-confirmation-status__icon--clock #mask-2 {
    fill: white; }
  .trip-details-row-confirmation-status__icon--clock #clock + g {
    fill: #F63440; }
  .trip-details-row-confirmation-status__icon--check #iconcheck-boundingbox {
    fill: #FFFFFF; }
  .trip-details-row-confirmation-status__icon--check #mask-3 {
    fill: white; }
  .trip-details-row-confirmation-status__icon--check #check + g {
    fill: #13AA37; }
