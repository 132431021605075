.bridge-pin {
  background: transparent;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .bridge-pin .marker-label {
    display: none; }
  .bridge-pin:hover {
    cursor: pointer; }
    .bridge-pin:hover .marker-label {
      display: block; }
  .bridge-pin__icon-wrapper {
    position: relative; }
  .bridge-pin__icon {
    position: absolute;
    top: 5px;
    left: 4px;
    color: #000;
    height: 24px;
    width: 24px; }
    .bridge-pin__icon img {
      margin: 0; }
