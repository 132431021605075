/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Lines - Variables
 *
 * This file defines line variables
 * used in components and utilities,
 * including horizontal rules and borders.
 *
 * 1) Line styles (sass map)
 * 2) Line getter function (get sass map values)
 *
 * See components/_line for line component classes.
 * See utilities/_borders for border utilities.
 *
 **/
/*------------------------------------------------------
 *
 * 1) Line styles sass map
 *
 *-----------------------------------------------------*/
/**
 * $Lines: sass-map
 *
 * This is a map of line styles
 * grouped by modification.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Line getter function
 *
 *-----------------------------------------------------*/
/**
 * get-line: Function
 *
 * @param $type
 * @param $variation (optional)
 *
 * This function takes a line style variation type from $Lines
 * and returns the appropriate value.
 * If no variation parameter is provided, the function
 * will return the base value.
 *
 * ie. get-line-width(thick) => 3px
 *     get-line-color(dark) => RGBA(...)
 **/
.reassign-dialog-body {
  position: relative; }
  .reassign-dialog-body .modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center; }
    .reassign-dialog-body .modal-header__close-button {
      position: absolute;
      right: 0; }
      .reassign-dialog-body .modal-header__close-button .icon-x {
        font-size: 1.5em;
        color: rgba(0, 0, 0, 0.2); }
  .reassign-dialog-body .reassign-btn-tertiary--negative {
    color: #FB6100; }
