@charset "UTF-8";
/**
 * Framework grid generation
 **/
/**
 * nth-root: Function
 *
 * @param $number
 * @param $root
 *
 * Returns the nth-root of a number
 *
 *
 * ie. ratio(font, desktop)
 * @debug nth-root(16, 2) == 4;
 *
 **/
/**
 * ratio: Function
 *
 * @param $scale
 * @param $device
 *
 * This function takes a type of scale (lineheight || font)
 * and a device type (mobile || desktop)
 * and returns the exponential ratio for the font scale.
 *
 * ie. ratio(font, desktop)
 **/
/**
 * Dimension - Mixins
 *
 * The following mixin is concerned only with helping apply shadows.
 *
 * Usage:
 *
 * box-shadow(2) <- applies a bottom directioned shadow
 * box-shadow(2, left) <- applies a left directioned shadow
 * box-shadow-reinforced(1, top) <- applies shadow with a reinforcing border.
 *
 **/
/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Grid Variables
 **/
/**
* get-breakpoint: Function
*
* @param $bp
*
* This function takes a breakpoint value (xs, sm, etc)
* and returns the pixel value for that breakpoint
*
* ie. get-breakpoint(xl) => 1200px
**/
/**
 * Fonts Variables
 *
 * This file defines font variables
 * that are used by type based patterns
 * such as headings and copy (see variables/_typography.scss).
 *
 * 1) Font constants
 * 2) Font styles sass map
 * 3) Font scale functions
 * 4) Font mixin
 *
 **/
/*------------------------------------------------------
 *
 * 1) Font constants
 * adjustments, sizes, smaller, base and max
 *
 * These values are used to compute font scale ratios
 * for each font.
 *
 *-----------------------------------------------------*/
/**
 * Font adjustments
 *
 * These constants are used to normalize the height difference
 * of lowercase letters between fonts.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Font style variables sass map
 *
 *-----------------------------------------------------*/
/**
 * $FONT_DATAMAP: sass-map
 *
 * This is a map of all font related variables.
 *
 * Description of each font variable group:
 *
 * face: the name used to declare the font.
 *
 * adjustments: the font face's x-height adjustment value.
 *
 * scale: these values are used to determine the overall scaling ratio
 * for the font scale for each font.
 *
 *   - numLarger: the number of larger sizes the font scale calculates the ratio with.
 *   - size: the size's exponential degree with which to apply the font scale ratio.
 *   - smaller: smaller size overrides.
 *   - base: base maximum font sizes (px) and line heights (ratio) for desktop and mobile.
 *   - max: maximum font sizes (px) and line heights (ratio) for desktop and mobile.
 *
 * Note that the base size is NOT the minimum font size,
 * rather the one optimized for comfortable reading.
 *
 **/
/*------------------------------------------------------
 *
 * 4) font Mixin
 *
 * @param $font
 * @param $size
 *
 * Use this function to create a responsive font style.
 *
 * Ex:
 * h1 {
 *   @include create-font(grubhubsans-bold, larger5)
 * }
 *
 *-----------------------------------------------------*/
/*------------------------------------------------------
 *
 * 3) Font scale functions and mixins
 *
 *-----------------------------------------------------*/
/**
 * font-size: Function
 *
 * @param $font
 * @param $size
 * @param $device
 *
 * This function takes a font name, size and device type
 * and returns the computed font size (px).
 *
 * ie. font-size(graphik, base, desktop)
 **/
/**
 * line-height: Function
 *
 * @param $font
 * @param $size
 * @param $device
 *
 * This function takes a font name, size and device type
 * and returns the computed line height ratio.
 *
 * ie. line-height(graphik, base, desktop)
 **/
/*
 * get-font-face: Function
 *
 * @param $family
 * @param $weight (optional)
 *
 * This function takes a font family and optional weight
 * and returns the font face name.
 *
 * ie. get-font-face(graphik, bold) => graphik-bold
 **/
lead,
.lead, .reassign-btn--large.reassign-btn-tertiary, .reassign-btn-group--large > .reassign-btn-tertiary.reassign-btn {
  font-family: graphik;
  font-weight: normal;
  font-size: 15px;
  line-height: 1.3575; }
  @media (min-width: 768px) {
    lead,
    .lead, .reassign-btn--large.reassign-btn-tertiary, .reassign-btn-group--large > .reassign-btn-tertiary.reassign-btn {
      font-size: 16.1px;
      line-height: 1.37946; } }

body,
.body, .reassign-btn.reassign-btn-tertiary, .reassign-dropdown-menu {
  font-family: graphik;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4; }
  @media (min-width: 768px) {
    body,
    .body, .reassign-btn.reassign-btn-tertiary, .reassign-dropdown-menu {
      font-size: 14px;
      line-height: 1.45; } }

caption,
.caption, .reassign-btn--small.reassign-btn-tertiary, .reassign-btn-group--small > .reassign-btn-tertiary.reassign-btn {
  font-family: graphik;
  font-weight: normal;
  font-size: 12px;
  line-height: 1.45; }
  @media (min-width: 768px) {
    caption,
    .caption, .reassign-btn--small.reassign-btn-tertiary, .reassign-btn-group--small > .reassign-btn-tertiary.reassign-btn {
      font-size: 12px;
      line-height: 1.45; } }

massive-heading,
.massive-heading {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 23.6px;
  line-height: 1.27993; }
  @media (min-width: 768px) {
    massive-heading,
    .massive-heading {
      font-size: 35.4px;
      line-height: 1.18253; } }

h1,
.h1 {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 22px;
  line-height: 1.32; }
  @media (min-width: 768px) {
    h1,
    .h1 {
      font-size: 30.8px;
      line-height: 1.243; } }

h2,
.h2 {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 20.5px;
  line-height: 1.36133; }
  @media (min-width: 768px) {
    h2,
    .h2 {
      font-size: 26.8px;
      line-height: 1.30656; } }

h3,
.h3 {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 19.1px;
  line-height: 1.40395; }
  @media (min-width: 768px) {
    h3,
    .h3 {
      font-size: 23.3px;
      line-height: 1.37337; } }

h4,
.h4 {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 17.8px;
  line-height: 1.44791; }
  @media (min-width: 768px) {
    h4,
    .h4 {
      font-size: 20.3px;
      line-height: 1.44359; } }

h5,
.h5, .reassign-btn--large, .reassign-btn-group--large > .reassign-btn {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 16.5px;
  line-height: 1.49325; }
  @media (min-width: 768px) {
    h5,
    .h5, .reassign-btn--large, .reassign-btn-group--large > .reassign-btn {
      font-size: 17.7px;
      line-height: 1.51741; } }

h6,
.h6, .reassign-btn {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 15.4px;
  line-height: 1.54; }
  @media (min-width: 768px) {
    h6,
    .h6, .reassign-btn {
      font-size: 15.4px;
      line-height: 1.595; } }

.reassign-btn--small, .reassign-btn-group--small > .reassign-btn {
  font-family: grubhubsans-bold;
  font-weight: normal;
  font-size: 13.2px;
  line-height: 1.595; }
  @media (min-width: 768px) {
    .reassign-btn--small, .reassign-btn-group--small > .reassign-btn {
      font-size: 13.2px;
      line-height: 1.595; } }

/**
 * Text - Variables
 *
 * This file defines text variables
 * used in components and utilities.
 *
 **/
/**
 * get-text-color: Function
 *
 **/
/**
 * Text - Variables
 *
 * This file defines text variables
 * used in components and utilities.
 *
 **/
/**
 * get-text-color: Function
 *
 **/
/**
 * Dimension - Variables
 *
 * Dimension Helper Methods
 * Dimension Constants
 * Dimension SCSS Map
 * Dimension Getter Function (get SCSS map values)
 *
 **/
/*------------------------------------------------------
 * PrivateVariables
 *-----------------------------------------------------*/
/*------------------------------------------------------
 * Constants
 *-----------------------------------------------------*/
/*------------------------------------------------------
 * SCSS Maps
 *-----------------------------------------------------*/
/*------------------------------------------------------
 * Getter Function
 *-----------------------------------------------------*/
/**
 * SPACE & GUTTERS
 **/
/**
 *
 * @param $level
 *
 * Use this function to calculate any spacing value on the spacing scale.
 *
  * Examples:
 * get-spacing-value(5) => 20px
 **/
/**
 * make-inset()
 * make-squished-inset()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-inset(3) === reassign-inset-3
 **/
/**
 * make-section()
 * @param $level
 *
 * Get one particular inset based on a level
 * @include make-section(3) === reassign-section-3
 **/
/**
 * make-static-gutter()
 * @param $level
 *
 * Get a gutter based on a level
 * @include make-gutter(1) === .reassign-gutter-1
 **/
/**
 * make-responsive-gutter()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Make a responsive gutter
 * @include make-responsive-gutter(3, 'sm') === reassign-gutter-sm-3
 **/
/**
 * make-stack-x|y()
 * @param $level
 *
 * Adds margins based on stack levels
 * @include make-stack-y(1) === .reassign-stack-y-1
 **/
/**
 * make-stack-cancel-up|down-x|y()
 * @param $level
 * @param $breakpoint 'xs|sm...|xl'
 *
 * Cancels stack by breakpoint up or down, on X or Y axis
 * @include make-stack-cancel-down-y('xs') === reassign-stack-cancel-xs
 **/
/*
 * Initialize the SPACING_MAP from the SPACING_LEVELS
 * the SPACING_MAP includes levels and values on the
 * a modular spacing scale.
 **/
/**
 * get-spacing-value: Function
 * get-gutter-value: Function
 *
 * @param $level
 *
 * This function takes a level from $SPACING_LEVELS / $SPACING MAP
 * and returns the pixel value of that level accordingly.
 *
 * Optionally include a modifier, which adjusts the level
 *
 * Examples:
 * get-spacing-value(6) => 24px
 * get-spacing-value(12, $modifier: -2) => 24px
 * get-gutter-value(1) => 4px
 **/
/**
 * Typography - Variables
 *
 * This file defines typography variables
 * used in components and utilties.
 *
 * 1) Typography styles (sass map)
 * 2) Typography getter functions
 *
 **/
/*------------------------------------------------------
 *
 * 2) Typography getter functions
 *
 * Use these functions to reference typography variables within styles.
 *
 *-----------------------------------------------------*/
/**
 * get-typography-font-style: Function
 *
 * @param $component
 * @param $weight (optional)
 *
 * This function takes a component type (ie. heading, Copy)
 * and optional weight and returns the font face value.
 *
 * ie. get-typography-font-style(headings)
       get-typography-font-style(copy, bold)
 **/
/**
 * get-typography-font-size: Function
 *
 * @param $component
 * @param $size
 *
 * This function takes a component type (ie. heading, copy)
 * and class (h1, h2, lead...) and returns the
 * font scale value.
 *
 * Refer to variables/_fonts for font scale values.
 * ie. get-typography-font-size(heading, h6) => base
       get-typography-font-size(copy, lead) => larger1
 **/
/**
 * Lines - Variables
 *
 * This file defines line variables
 * used in components and utilities,
 * including horizontal rules and borders.
 *
 * 1) Line styles (sass map)
 * 2) Line getter function (get sass map values)
 *
 * See components/_line for line component classes.
 * See utilities/_borders for border utilities.
 *
 **/
/*------------------------------------------------------
 *
 * 1) Line styles sass map
 *
 *-----------------------------------------------------*/
/**
 * $Lines: sass-map
 *
 * This is a map of line styles
 * grouped by modification.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Line getter function
 *
 *-----------------------------------------------------*/
/**
 * get-line: Function
 *
 * @param $type
 * @param $variation (optional)
 *
 * This function takes a line style variation type from $Lines
 * and returns the appropriate value.
 * If no variation parameter is provided, the function
 * will return the base value.
 *
 * ie. get-line-width(thick) => 3px
 *     get-line-color(dark) => RGBA(...)
 **/
/**
 * Borders - Variables
 *
 * This file defines border radius variables
 * used in components and utilities.
 *
 * 1) Border radius sizes(sass map)
 * 2) @function get-border-radius
 * 3) @mixin round-side
 *
 **/
/*------------------------------------------------------
 *
 * 1) Border radius styles
 *
 *-----------------------------------------------------*/
/**
 * $BorderRadius: sass-map
 *
 * Map of border radius sizes.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Border radius getter function
 *
 *-----------------------------------------------------*/
/**
 * get-border-radius: Function
 *
 * @param $size (optional)
 *
 * This function takes a border radius size
 * and returns the pixel value for that size.
 * If no param is specified, the base radius is returned.
 *
 * ie. get-border-radius(large) => 3px
 *
 **/
.icon-xs, .icon-16 {
  font-size: 16px; }

.icon-sm, .icon-24 {
  font-size: 24px; }

.icon-md, .icon-28 {
  font-size: 28px; }

.icon-lg, .icon-32 {
  font-size: 32px; }

.icon-xl, .icon-40 {
  font-size: 40px; }

.icon-48 {
  font-size: 48px !important; }

.icon-64 {
  font-size: 64px !important; }

.icon-xs,
.icon-sm,
.icon-md,
.icon-lg,
.icon-xl {
  line-height: 1; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'grubhub-ico';
  font-style: normal;
  font-feature-settings: "liga";
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-x:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-caret-down:before {
  content: ""; }

.icon-pencil:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-caret-left:before {
  content: ""; }

.icon-exclamation:before {
  content: ""; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

body {
  -webkit-font-smoothing: antialiased;
  margin: 0; }

input[type="search"] {
  box-sizing: border-box; }

/**
 * declare-font: Mixin
 *
 * @param $name
 * @param $weight
 * @param $style
 * @param $path
 * @param $font-file-name
 *
 * This mixin creates a font face declaration
 * given font-family name, source paths, and style information.
 *
 **/
@font-face {
  font-family: "graphik";
  src: url("fonts/graphik-regular/graphik-regular.woff") format("woff"), url("fonts/graphik-regular/graphik-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "graphik-bold";
  src: url("fonts/graphik-bold/graphik-bold.woff") format("woff"), url("fonts/graphik-bold/graphik-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "grubhubsans";
  src: url("fonts/grubhub-sans-light/grubhub-sans-light.woff") format("woff"), url("fonts/grubhub-sans-light/grubhub-sans-light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "grubhubsans-bold";
  src: url("fonts/grubhub-sans-bold/grubhub-sans-bold.woff") format("woff"), url("fonts/grubhub-sans-bold/grubhub-sans-bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "grubhub-ico";
  src: url("fonts/icons/grubhub.woff") format("woff"), url("fonts/icons/grubhub.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.reassign-hidden-accessible, .reassign-radio-group .reassign-radio-label .reassign-radio-input {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

/**
 * Flexbox utilities
 *
 *
 *  Creates order, align-items, align-self and justify-content utility classesfor all provided breakpoints.
 */
.reassign-flexbox-order-1 {
  order: 1; }

.reassign-flexbox-order-2 {
  order: 2; }

.reassign-flexbox-order-3 {
  order: 3; }

.reassign-flexbox-order-4 {
  order: 4; }

.reassign-flexbox-order-5 {
  order: 5; }

.reassign-flexbox-order-6 {
  order: 6; }

.reassign-flexbox-order-7 {
  order: 7; }

.reassign-flexbox-order-8 {
  order: 8; }

.reassign-flexbox-order-9 {
  order: 9; }

.reassign-flexbox-order-10 {
  order: 10; }

.reassign-flex {
  display: flex; }

.reassign-flex-reset-static {
  flex: 0 0 auto; }

.reassign-flex-reset-grow {
  flex: 1 0 auto; }

.reassign-flex-reset-shrink {
  flex: 1 0 auto; }

.reassign-flex-reset-flexible {
  flex: 1 1 auto; }

.reassign-flex-direction-row {
  flex-direction: row; }

.reassign-flex-direction-column {
  flex-direction: column; }

.reassign-flex-wrap {
  flex-wrap: wrap; }

.reassign-flex-wrap-reverse {
  flex-wrap: wrap-reverse; }

.reassign-flex-no-wrap {
  flex-wrap: nowrap; }

.reassign-flex-align-xs--top {
  align-items: flex-start; }

.reassign-flex-align-xs--center, .reassign-dropdown-toggle {
  align-items: center; }

.reassign-flex-align-xs--bottom {
  align-items: flex-end; }

.reassign-flex-align-xs--stretch {
  align-items: stretch; }

.reassign-flex-align-xs--baseline {
  align-items: baseline; }

.reassign-flex-align-self-xs--auto {
  align-self: auto; }

.reassign-flex-align-self-xs--top {
  align-self: flex-start; }

.reassign-flex-align-self-xs--center {
  align-self: center; }

.reassign-flex-align-self-xs--bottom {
  align-self: flex-end; }

.reassign-flex-align-self-xs--baseline {
  align-self: baseline; }

.reassign-flex-align-self-xs--stretch {
  align-self: stretch; }

.reassign-flex-justify-xs--left {
  justify-content: flex-start; }

.reassign-flex-justify-xs--center {
  justify-content: center; }

.reassign-flex-justify-xs--right {
  justify-content: flex-end; }

.reassign-flex-justify-xs--around {
  justify-content: space-around; }

.reassign-flex-justify-xs--between, .reassign-dropdown-toggle {
  justify-content: space-between; }

.reassign-flex-direction-xs-row {
  flex-direction: row; }

.reassign-flex-direction-xs-column {
  flex-direction: column; }

@media (min-width: 576px) {
  .reassign-flex-align-sm--top {
    align-items: flex-start; }
  .reassign-flex-align-sm--center {
    align-items: center; }
  .reassign-flex-align-sm--bottom {
    align-items: flex-end; }
  .reassign-flex-align-sm--stretch {
    align-items: stretch; }
  .reassign-flex-align-sm--baseline {
    align-items: baseline; }
  .reassign-flex-align-self-sm--auto {
    align-self: auto; }
  .reassign-flex-align-self-sm--top {
    align-self: flex-start; }
  .reassign-flex-align-self-sm--center {
    align-self: center; }
  .reassign-flex-align-self-sm--bottom {
    align-self: flex-end; }
  .reassign-flex-align-self-sm--baseline {
    align-self: baseline; }
  .reassign-flex-align-self-sm--stretch {
    align-self: stretch; }
  .reassign-flex-justify-sm--left {
    justify-content: flex-start; }
  .reassign-flex-justify-sm--center {
    justify-content: center; }
  .reassign-flex-justify-sm--right {
    justify-content: flex-end; }
  .reassign-flex-justify-sm--around {
    justify-content: space-around; }
  .reassign-flex-justify-sm--between {
    justify-content: space-between; }
  .reassign-flex-direction-sm-row {
    flex-direction: row; }
  .reassign-flex-direction-sm-column {
    flex-direction: column; } }

@media (min-width: 768px) {
  .reassign-flex-align-md--top {
    align-items: flex-start; }
  .reassign-flex-align-md--center {
    align-items: center; }
  .reassign-flex-align-md--bottom {
    align-items: flex-end; }
  .reassign-flex-align-md--stretch {
    align-items: stretch; }
  .reassign-flex-align-md--baseline {
    align-items: baseline; }
  .reassign-flex-align-self-md--auto {
    align-self: auto; }
  .reassign-flex-align-self-md--top {
    align-self: flex-start; }
  .reassign-flex-align-self-md--center {
    align-self: center; }
  .reassign-flex-align-self-md--bottom {
    align-self: flex-end; }
  .reassign-flex-align-self-md--baseline {
    align-self: baseline; }
  .reassign-flex-align-self-md--stretch {
    align-self: stretch; }
  .reassign-flex-justify-md--left {
    justify-content: flex-start; }
  .reassign-flex-justify-md--center {
    justify-content: center; }
  .reassign-flex-justify-md--right {
    justify-content: flex-end; }
  .reassign-flex-justify-md--around {
    justify-content: space-around; }
  .reassign-flex-justify-md--between {
    justify-content: space-between; }
  .reassign-flex-direction-md-row {
    flex-direction: row; }
  .reassign-flex-direction-md-column {
    flex-direction: column; } }

@media (min-width: 992px) {
  .reassign-flex-align-lg--top {
    align-items: flex-start; }
  .reassign-flex-align-lg--center {
    align-items: center; }
  .reassign-flex-align-lg--bottom {
    align-items: flex-end; }
  .reassign-flex-align-lg--stretch {
    align-items: stretch; }
  .reassign-flex-align-lg--baseline {
    align-items: baseline; }
  .reassign-flex-align-self-lg--auto {
    align-self: auto; }
  .reassign-flex-align-self-lg--top {
    align-self: flex-start; }
  .reassign-flex-align-self-lg--center {
    align-self: center; }
  .reassign-flex-align-self-lg--bottom {
    align-self: flex-end; }
  .reassign-flex-align-self-lg--baseline {
    align-self: baseline; }
  .reassign-flex-align-self-lg--stretch {
    align-self: stretch; }
  .reassign-flex-justify-lg--left {
    justify-content: flex-start; }
  .reassign-flex-justify-lg--center {
    justify-content: center; }
  .reassign-flex-justify-lg--right {
    justify-content: flex-end; }
  .reassign-flex-justify-lg--around {
    justify-content: space-around; }
  .reassign-flex-justify-lg--between {
    justify-content: space-between; }
  .reassign-flex-direction-lg-row {
    flex-direction: row; }
  .reassign-flex-direction-lg-column {
    flex-direction: column; } }

/**
 * Text - Utilities
 *
 * This file defines text utility classes.
 *
 * Use these classes for text color,
 * alignment, etc. where standard component
 * styles cannot be applied.
 * See variables/_text for text colors.
 *
 **/
.reassign-text-left {
  text-align: left; }

.reassign-text-center {
  text-align: center; }

.reassign-text-right {
  text-align: center; }

.reassign-text-primary {
  color: rgba(0, 0, 0, 0.87); }

.reassign-text-secondary {
  color: rgba(0, 0, 0, 0.55); }

/**
 * Gutters, Boxes & Sections
 *
 * 1) Imports
 * 2) Global vars
 * 3) Box & Section mixins
 * 4) Gutter mixins
 * 5) Declarations
 * 6) Margin & Padding
 **/
/*------------------------------------------------------
 * Paddings and Margins
 * LEGACY - (vnalupta) remove when safe
 *-----------------------------------------------------*/
.reassign-margin-top-default {
  margin-top: 5px; }

.reassign-padding-top-default {
  padding-top: 5px; }

.reassign-margin-right-default {
  margin-right: 5px; }

.reassign-padding-right-default {
  padding-right: 5px; }

.reassign-margin-bottom-default {
  margin-bottom: 5px; }

.reassign-padding-bottom-default {
  padding-bottom: 5px; }

.reassign-margin-left-default {
  margin-left: 5px; }

.reassign-padding-left-default {
  padding-left: 5px; }

.reassign-margin-top-large {
  margin-top: 10px; }

.reassign-padding-top-large {
  padding-top: 10px; }

.reassign-margin-right-large {
  margin-right: 10px; }

.reassign-padding-right-large {
  padding-right: 10px; }

.reassign-margin-bottom-large {
  margin-bottom: 10px; }

.reassign-padding-bottom-large {
  padding-bottom: 10px; }

.reassign-margin-left-large {
  margin-left: 10px; }

.reassign-padding-left-large {
  padding-left: 10px; }

.reassign-margin-top-extra {
  margin-top: 35px; }

.reassign-padding-top-extra {
  padding-top: 35px; }

.reassign-margin-right-extra {
  margin-right: 35px; }

.reassign-padding-right-extra {
  padding-right: 35px; }

.reassign-margin-bottom-extra {
  margin-bottom: 35px; }

.reassign-padding-bottom-extra {
  padding-bottom: 35px; }

.reassign-margin-left-extra {
  margin-left: 35px; }

.reassign-padding-left-extra {
  padding-left: 35px; }

.reassign-gutter-1 {
  padding-left: 4px;
  padding-right: 4px; }

@media (max-width: 575px) {
  .reassign-gutter-xs-1 {
    padding-left: 4px;
    padding-right: 4px; } }

@media (max-width: 767px) {
  .reassign-gutter-sm-1 {
    padding-left: 4px;
    padding-right: 4px; } }

@media (max-width: 991px) {
  .reassign-gutter-md-1 {
    padding-left: 4px;
    padding-right: 4px; } }

@media (max-width: 1199px) {
  .reassign-gutter-lg-1 {
    padding-left: 4px;
    padding-right: 4px; } }

.reassign-gutter-xl-1 {
  padding-left: 4px;
  padding-right: 4px; }

.reassign-gutter-2 {
  padding-left: 8px;
  padding-right: 8px; }

@media (max-width: 575px) {
  .reassign-gutter-xs-2 {
    padding-left: 8px;
    padding-right: 8px; } }

@media (max-width: 767px) {
  .reassign-gutter-sm-2 {
    padding-left: 8px;
    padding-right: 8px; } }

@media (max-width: 991px) {
  .reassign-gutter-md-2 {
    padding-left: 8px;
    padding-right: 8px; } }

@media (max-width: 1199px) {
  .reassign-gutter-lg-2 {
    padding-left: 8px;
    padding-right: 8px; } }

.reassign-gutter-xl-2 {
  padding-left: 8px;
  padding-right: 8px; }

.reassign-gutter-3 {
  padding-left: 12px;
  padding-right: 12px; }

@media (max-width: 575px) {
  .reassign-gutter-xs-3 {
    padding-left: 12px;
    padding-right: 12px; } }

@media (max-width: 767px) {
  .reassign-gutter-sm-3 {
    padding-left: 12px;
    padding-right: 12px; } }

@media (max-width: 991px) {
  .reassign-gutter-md-3 {
    padding-left: 12px;
    padding-right: 12px; } }

@media (max-width: 1199px) {
  .reassign-gutter-lg-3 {
    padding-left: 12px;
    padding-right: 12px; } }

.reassign-gutter-xl-3 {
  padding-left: 12px;
  padding-right: 12px; }

.reassign-inset-1 {
  padding: 4px; }

.reassign-inset-2 {
  padding: 8px; }

.reassign-inset-3 {
  padding: 12px; }

.reassign-inset-4 {
  padding: 16px; }

.reassign-inset-6 {
  padding: 24px; }

.reassign-section-2 {
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 768px) {
    .reassign-section-2 {
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 32px;
      padding-right: 32px; } }

.reassign-section-3 {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 768px) {
    .reassign-section-3 {
      padding-top: 16px;
      padding-bottom: 16px;
      padding-left: 32px;
      padding-right: 32px; } }

.reassign-section-6 {
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 768px) {
    .reassign-section-6 {
      padding-top: 32px;
      padding-bottom: 32px;
      padding-left: 32px;
      padding-right: 32px; } }

.reassign-section-12 {
  padding-top: 48px;
  padding-bottom: 48px;
  padding-left: 16px;
  padding-right: 16px; }
  @media (min-width: 768px) {
    .reassign-section-12 {
      padding-top: 64px;
      padding-bottom: 64px;
      padding-left: 32px;
      padding-right: 32px; } }

.reassign-stack-y-0 {
  margin-bottom: 0px; }

.reassign-stack-x-0 {
  margin-right: 0px; }

.reassign-stack-y-half {
  margin-bottom: 2px; }

.reassign-stack-x-half {
  margin-right: 2px; }

.reassign-stack-y-1 {
  margin-bottom: 4px; }

.reassign-stack-x-1 {
  margin-right: 4px; }

.reassign-stack-y-2 {
  margin-bottom: 8px; }

.reassign-stack-x-2 {
  margin-right: 8px; }

.reassign-stack-y-3 {
  margin-bottom: 12px; }

.reassign-stack-x-3 {
  margin-right: 12px; }

.reassign-stack-y-4 {
  margin-bottom: 16px; }

.reassign-stack-x-4 {
  margin-right: 16px; }

.reassign-stack-y-6 {
  margin-bottom: 24px; }

.reassign-stack-x-6 {
  margin-right: 24px; }

.reassign-stack-y-8 {
  margin-bottom: 32px; }

.reassign-stack-x-8 {
  margin-right: 32px; }

.reassign-stack-y-12 {
  margin-bottom: 48px; }

.reassign-stack-x-12 {
  margin-right: 48px; }

.reassign-stack-y-16 {
  margin-bottom: 64px; }

.reassign-stack-x-16 {
  margin-right: 64px; }

.reassign-rounded {
  border-radius: 3px; }

.reassign-background {
  background: #FFF; }

/**
 * Lines - Utilities
 *
 * See variables/_lines for line values.
 *
 **/
.reassign-line-top {
  border-top: 2px solid rgba(0, 0, 0, 0.2); }

/* WIDTH MODIFIERS
/* --------------------------------------- */
.reassign-line--thin {
  border-width: 1px; }

/* COLOR MODIFIERS
/* --------------------------------------- */
.reassign-line--light {
  border-color: rgba(0, 0, 0, 0.1); }

/**
 * Typography - Component
 *
 * This file defines typography styles
 * including heading and copy components.
 *
 * 1) Typography classes (sass map)
 * 2) Typography getter functions
 * 3) Common typography styles
 * 4) Typography mixins
 * 5) Sass to Js
 *
 **/
/*------------------------------------------------------
 *
 * 1) Typography Classes Sass Map
 *
 *-----------------------------------------------------*/
/*------------------------------------------------------
 *
 * 2) Typography getter functions
 *
 *-----------------------------------------------------*/
/**
 * get-typography-classes: Function
 *
 * @param $component
 *
 * This function takes a component (headings, copy)
 * and returns a map of class names for that component.
 *
 * ie. get-typography-classes(headings) => ( headings: ( massive-heading, ...) )
 **/
/**
 * set-typography-styles: Function
 *
 * This function generates a map of font size and line height
 * values for a given heading or copy class to be displayed on the UI.
 * Each class's data is merged into the $map given.
 *
 **/
/*------------------------------------------------------
 *
 * 3) Common typography styles
 *
 *-----------------------------------------------------*/
p,
body,
.body {
  color: rgba(0, 0, 0, 0.87); }

.dark-theme, .reassign-toaster,
.dark-theme p,
.reassign-toaster p,
.dark-theme .body,
.reassign-toaster .body {
  color: #FFF; }

.type-secondary a,
.type-secondary .reassign-link {
  opacity: 1.0; }

body {
  font-family: graphik;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  font-weight: normal; }
  @media (min-width: 768px) {
    body {
      font-size: 14px;
      line-height: 1.45; } }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6, p, .caption {
  margin-top: 0;
  margin-bottom: 4px; }

p {
  margin-bottom: 8px; }

p,
.body {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/*------------------------------------------------------
 *
 * 4) Typography mixin : creates type classes.
 *
 *-----------------------------------------------------*/
/*
 * This function creates:
 *
 * 1) Typography classes for heading and copy components.
 * 2) A typography data map to display font size and line height on the UI.
 *
 **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/**
            * Create classes for each class in $HeadingSizes.
            *
            * Ex:
            * $class = h1
            * h1, .h1 { font definition... }
            *
            **/
/*------------------------------------------------------
 * Sass to Js
 *-----------------------------------------------------*/
.typography-data:before {
  content: sassToJs((headings: (massive-heading: (mobile: (font-size: 23.6px, line-height: 1.27993), desktop: (font-size: 35.4px, line-height: 1.18253)), h1: (mobile: (font-size: 22px, line-height: 1.32), desktop: (font-size: 30.8px, line-height: 1.243)), h2: (mobile: (font-size: 20.5px, line-height: 1.36133), desktop: (font-size: 26.8px, line-height: 1.30656)), h3: (mobile: (font-size: 19.1px, line-height: 1.40395), desktop: (font-size: 23.3px, line-height: 1.37337)), h4: (mobile: (font-size: 17.8px, line-height: 1.44791), desktop: (font-size: 20.3px, line-height: 1.44359)), h5: (mobile: (font-size: 16.5px, line-height: 1.49325), desktop: (font-size: 17.7px, line-height: 1.51741)), h6: (mobile: (font-size: 15.4px, line-height: 1.54), desktop: (font-size: 15.4px, line-height: 1.595))), copy: (lead: (mobile: (font-size: 15px, line-height: 1.3575), desktop: (font-size: 16.1px, line-height: 1.37946)), body: (mobile: (font-size: 14px, line-height: 1.4), desktop: (font-size: 14px, line-height: 1.45)), caption: (mobile: (font-size: 12px, line-height: 1.45), desktop: (font-size: 12px, line-height: 1.45)))));
  display: none; }

/**
 * Buttons
 *
 * 1) Mixins
 * 2) Components
 *
 **/
/**
 * interaction-states: mixin
 *
 * @param $borderColor
 * @param $fontColor
 * @param $backgroundColor
 *
 * This mixin takes border, font and background colors
 * and creates hover, focus and active button styles.
 * Does not include the styles for button groups.
 *
 **/
/*
 *
 * COMPONENTS
 *
 **/
.reassign-btn {
  border-radius: 4px;
  padding: 12px 16px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  border-width: 2px;
  border-style: solid;
  color: #FFF;
  background: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  transition: background 0.2s ease, border-color 0.2s ease, color 0.2s ease;
  text-size-adjust: 100%; }
  .reassign-btn:hover, .reassign-btn:focus {
    color: #FFF; }
  .reassign-btn:hover, .reassign-btn:focus, .reassign-btn:active {
    box-shadow: none; }
  .reassign-btn:hover, .reassign-btn:focus, .reassign-btn.focus {
    text-decoration: none;
    cursor: pointer; }

/* SIZE MODIFIERS
/* --------------------------------------- */
.reassign-btn--small, .reassign-btn-group--small > .reassign-btn {
  padding: 8px 16px; }

.reassign-btn--large, .reassign-btn-group--large > .reassign-btn {
  padding: 16px 20px; }

/* BLOCK MODIFIER
/* --------------------------------------- */
.reassign-btn--block {
  display: block;
  width: 100%; }

/* ROUND MODIFIER
/* --------------------------------------- */
.reassign-btn--round {
  border-radius: 50%; }

/**
 * MAIN BUTTON TYPES
 *
 * 1) .reassign-btn-primary
 * 2) .reassign-btn-secondary
 * 3) .reassign-btn-tertiary
/* --------------------------------------- */
.reassign-btn-primary {
  color: #FFF;
  background: #0070EB;
  border-color: #0070EB; }
  .reassign-btn-primary:hover, .reassign-btn-primary:focus {
    border-color: #0058b8;
    background: #0058b8;
    color: #FFF; }
  .reassign-btn-primary:active {
    border-color: #003f85;
    background: #003f85; }
  .reassign-btn-primary:disabled, .reassign-btn-primary[disabled] {
    background: #CACACA !important;
    border-color: #CACACA !important;
    color: #FFF !important;
    pointer-events: none;
    cursor: not-allowed !important; }

.reassign-btn-secondary {
  color: #0070EB;
  background: rgba(0, 0, 0, 0);
  border-color: #0070EB; }
  .reassign-btn-secondary:hover, .reassign-btn-secondary:focus {
    border-color: #0058b8;
    color: #0058b8; }
  .reassign-btn-secondary:active {
    border-color: #003f85;
    color: #003f85; }
  .reassign-btn-secondary:disabled, .reassign-btn-secondary[disabled] {
    background: rgba(0, 0, 0, 0) !important;
    border-color: #CACACA !important;
    color: #CACACA !important;
    pointer-events: none;
    cursor: not-allowed !important; }

.reassign-btn-tertiary {
  color: #0070EB;
  background: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0); }
  .reassign-btn-tertiary:hover, .reassign-btn-tertiary:focus {
    color: #0058b8; }
  .reassign-btn-tertiary:active {
    color: #003f85; }
  .reassign-btn-tertiary:disabled, .reassign-btn-tertiary[disabled] {
    background: rgba(0, 0, 0, 0) !important;
    border-color: rgba(0, 0, 0, 0) !important;
    color: #CACACA !important;
    pointer-events: none;
    cursor: not-allowed !important; }

/* BUTTON COLOR MODIFIERS

 * 1) .reassign-btn-{type}--brand
 * 2) .reassign-btn-{type}--positive
 * 3) .reassign-btn-{type}--negative
 * 4) .reassign-btn-{type}--disabled
 * 5) .reassign-btn-{type}--facebook // primary only
 * 6) .reassign-btn-{type}--inverted // secondary and tertiary only
 /* --------------------------------------- */
.reassign-btn-primary--brand {
  color: #FFF;
  background: #13AA37;
  border-color: #13AA37; }
  .reassign-btn-primary--brand:hover, .reassign-btn-primary--brand:focus {
    border-color: #0e7c28;
    background: #0e7c28;
    color: #FFF; }
  .reassign-btn-primary--brand:active {
    border-color: #094e19;
    background: #094e19; }

.reassign-btn-primary--positive {
  color: #FFF;
  background: #13AA37;
  border-color: #13AA37; }
  .reassign-btn-primary--positive:hover, .reassign-btn-primary--positive:focus {
    border-color: #0e7c28;
    background: #0e7c28;
    color: #FFF; }
  .reassign-btn-primary--positive:active {
    border-color: #094e19;
    background: #094e19; }

.reassign-btn-secondary--positive {
  color: #13AA37;
  background: rgba(0, 0, 0, 0);
  border-color: #13AA37; }
  .reassign-btn-secondary--positive:hover, .reassign-btn-secondary--positive:focus {
    border-color: #0e7c28;
    color: #0e7c28; }
  .reassign-btn-secondary--positive:active {
    border-color: #094e19;
    color: #094e19; }

.reassign-btn-primary--negative {
  color: #FFF;
  background: #FB6100;
  border-color: #FB6100; }
  .reassign-btn-primary--negative:hover, .reassign-btn-primary--negative:focus {
    border-color: #c84d00;
    background: #c84d00;
    color: #FFF; }
  .reassign-btn-primary--negative:active {
    border-color: #953a00;
    background: #953a00; }

.reassign-btn-secondary--negative {
  color: #FB6100;
  background: rgba(0, 0, 0, 0);
  border-color: #FB6100; }
  .reassign-btn-secondary--negative:hover, .reassign-btn-secondary--negative:focus {
    border-color: #c84d00;
    color: #c84d00; }
  .reassign-btn-secondary--negative:active {
    border-color: #953a00;
    color: #953a00; }

.reassign-btn-primary--disabled {
  color: #FFF;
  background: #CACACA;
  border-color: #CACACA; }
  .reassign-btn-primary--disabled:hover, .reassign-btn-primary--disabled:focus {
    border-color: #b1b1b1;
    background: #b1b1b1;
    color: #FFF; }
  .reassign-btn-primary--disabled:active {
    border-color: #979797;
    background: #979797; }

.reassign-btn-secondary--disabled {
  color: #CACACA;
  background: rgba(0, 0, 0, 0);
  border-color: #CACACA; }
  .reassign-btn-secondary--disabled:hover, .reassign-btn-secondary--disabled:focus {
    border-color: #b1b1b1;
    color: #b1b1b1; }
  .reassign-btn-secondary--disabled:active {
    border-color: #979797;
    color: #979797; }

.reassign-btn-primary--inverted {
  color: #0070EB;
  background: #FFF;
  border-color: #FFF; }
  .reassign-btn-primary--inverted:hover, .reassign-btn-primary--inverted:focus {
    border-color: rgba(255, 255, 255, 0.75);
    background: white;
    color: #0058b8; }
  .reassign-btn-primary--inverted:active {
    border-color: rgba(255, 255, 255, 0.75);
    background: #E4E4E4;
    color: #003f85; }

.reassign-btn-secondary--inverted {
  color: #FFF;
  background: rgba(0, 0, 0, 0);
  border-color: #FFF; }
  .reassign-btn-secondary--inverted:hover, .reassign-btn-secondary--inverted:focus {
    border-color: rgba(255, 255, 255, 0.75);
    color: rgba(255, 255, 255, 0.75); }
  .reassign-btn-secondary--inverted:active {
    border-color: rgba(255, 255, 255, 0.75);
    color: rgba(255, 255, 255, 0.75); }

.reassign-btn-tertiary--inverted {
  color: #FFF;
  background: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0); }
  .reassign-btn-tertiary--inverted:hover, .reassign-btn-tertiary--inverted:focus {
    color: rgba(255, 255, 255, 0.75); }
  .reassign-btn-tertiary--inverted:active {
    color: rgba(255, 255, 255, 0.75); }

.reassign-btn-primary--signIn {
  color: #FFF;
  background: #F63440;
  border-color: #F63440; }
  .reassign-btn-primary--signIn:hover, .reassign-btn-primary--signIn:focus {
    border-color: #ed0a18;
    background: #ed0a18;
    color: #FFF; }
  .reassign-btn-primary--signIn:active {
    border-color: #bc0813;
    background: #bc0813; }

.reassign-btn-group {
  display: flex;
  /* BUTTON GROUP STATES
    /* --------------------------------------- */ }
  .reassign-btn-group > .reassign-btn:first-child:not(:last-child),
  .reassign-btn-group > .reassign-input-group:first-child:not(:last-child) > .reassign-form-control {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .reassign-btn-group > .reassign-btn:last-child:not(:first-child),
  .reassign-btn-group > .reassign-input-group:last-child:not(:first-child) > .reassign-form-control {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .reassign-btn-group > .reassign-btn:not(:first-child):not(:last-child),
  .reassign-btn-group > .reassign-input-group:not(:first-child):not(:last-child) > .reassign-form-control {
    border-radius: 0; }
  .reassign-btn-group .reassign-btn + .reassign-btn {
    border-left-color: rgba(0, 0, 0, 0.1); }
  .reassign-btn-group .reassign-btn + .reassign-btn,
  .reassign-btn-group .reassign-input-group .reassign-form-control {
    margin-left: -2px; }
  .reassign-btn-group .reassign-input-group .reassign-form-control {
    height: 100%; }
  .reassign-btn-group > .reassign-btn {
    flex: 1;
    border-color: rgba(0, 0, 0, 0.2);
    white-space: normal;
    transition-property: z-index, background, border-color, color;
    transition-timing-function: ease;
    transition-duration: 0.1s; }
    .reassign-btn-group > .reassign-btn:hover, .reassign-btn-group > .reassign-btn.hover, .reassign-btn-group > .reassign-btn:active, .reassign-btn-group > .reassign-btn.active {
      z-index: 1000; }
  .reassign-btn-group > .reassign-btn {
    border-color: rgba(0, 0, 0, 0.2);
    color: #0070EB;
    background-color: rgba(0, 0, 0, 0); }
    .reassign-btn-group > .reassign-btn:hover, .reassign-btn-group > .reassign-btn.reassign-btn--hover {
      border-color: #0058b8;
      color: #FFF;
      background-color: #0058b8; }
    .reassign-btn-group > .reassign-btn:active, .reassign-btn-group > .reassign-btn.reassign-btn--active {
      border-color: #003f85;
      color: #FFF;
      background-color: #003f85; }
    .reassign-btn-group > .reassign-btn .active, .reassign-btn-group > .reassign-btn.reassign-btn--selected {
      border-color: #0070EB;
      color: #FFF;
      background-color: #0070EB; }
    .reassign-btn-group > .reassign-btn:disabled, .reassign-btn-group > .reassign-btn[disabled] {
      background: rgba(0, 0, 0, 0) !important;
      border-color: #CACACA !important;
      color: #CACACA !important;
      pointer-events: none;
      cursor: not-allowed !important; }

/* BUTTON GROUP SIZE MODIFICATIONS
/* --------------------------------------- */
/* ICON BUTTONS
/* --------------------------------------- */
.reassign-iconBtn {
  border: 0;
  padding: 0;
  border-radius: 50%;
  height: 44px;
  width: 44px; }
  .reassign-iconBtn > [class^="icon"] {
    line-height: 44px;
    font-size: 28px; }
  .reassign-iconBtn.reassign-btn-secondary {
    box-shadow: inset 0 0 0 2px #0070EB; }
    .reassign-iconBtn.reassign-btn-secondary[disabled] {
      box-shadow: inset 0 0 0 2px #CACACA; }

.reassign-btn-tertiary--inverted:hover, .reassign-btn-tertiary--inverted:focus,
.reassign-btn-secondary--inverted:hover,
.reassign-btn-secondary--inverted:focus {
  color: #FFF;
  background: rgba(255, 255, 255, 0.2); }

.reassign-btn-tertiary--inverted:active,
.reassign-btn-secondary--inverted:active {
  background: rgba(255, 255, 255, 0.4); }

.reassign-iconBtn--xsmall {
  height: 36px;
  width: 36px; }
  .reassign-iconBtn--xsmall > [class^="icon"] {
    line-height: 36px;
    font-size: 16px; }

.reassign-iconBtn--small {
  height: 36px;
  width: 36px; }
  .reassign-iconBtn--small > [class^="icon"] {
    line-height: 36px;
    font-size: 23px; }

/**
 * Inputs
 *
 * 1) Mixins
 * 2) Components
 *
 **/
/*
 *
 * COMPONENTS
 *
 **/
.reassign-form-control {
  transition: border-color 0.1s ease;
  font-family: graphik;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  display: block;
  width: 100%;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.87);
  background: #FFF;
  box-shadow: none;
  padding: 12px 16px; }
  .reassign-form-control:-moz-placeholder {
    color: rgba(0, 0, 0, 0.35); }
  .reassign-form-control::-moz-placeholder {
    color: rgba(0, 0, 0, 0.35); }
  .reassign-form-control::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.35); }
  .reassign-form-control:-ms-input-placeholder {
    color: rgba(0, 0, 0, 0.35); }
  @media (min-width: 768px) {
    .reassign-form-control {
      font-size: 14px;
      line-height: 1.45; } }
  .reassign-form-control:hover, .reassign-form-control:focus {
    box-shadow: none; }
  .reassign-form-control:focus {
    border-color: #0070EB !important; }
  .reassign-form-control:disabled {
    opacity: 0.5;
    cursor: not-allowed; }
    .reassign-form-control:disabled:-moz-placeholder {
      color: #CACACA; }
    .reassign-form-control:disabled::-moz-placeholder {
      color: #CACACA; }
    .reassign-form-control:disabled::-webkit-input-placeholder {
      color: #CACACA; }
    .reassign-form-control:disabled:-ms-input-placeholder {
      color: #CACACA; }
  .reassign-form-control.reassign-form-control--invalid {
    border-color: #FB6100; }
    .reassign-form-control.reassign-form-control--invalid + .reassign-icon {
      color: #FB6100; }
  .reassign-form-control.reassign-form-control--valid {
    border-color: #13AA37; }
    .reassign-form-control.reassign-form-control--valid + .reassign-icon {
      color: #13AA37; }

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

.reassign-radio-group {
  margin-bottom: 8px;
  box-sizing: border-box; }
  .reassign-radio-group .reassign-radio-label {
    cursor: pointer;
    vertical-align: bottom; }
    .reassign-radio-group .reassign-radio-label .reassign-radio-input:focus + .reassign-radio-filler,
    .reassign-radio-group .reassign-radio-label .reassign-radio-input:focus + .reassign-radio-text {
      outline: 1px solid #0070EB !important; }
    .reassign-radio-group .reassign-radio-label .reassign-radio-filler {
      position: relative;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transition: border-color 0.1s ease;
      border: 2px solid #CACACA;
      width: 20px;
      height: 20px;
      background-color: #FFF;
      margin-right: 4px;
      vertical-align: bottom; }
      .reassign-radio-group .reassign-radio-label .reassign-radio-filler:before {
        border-radius: 50%;
        transition: background 0.1s ease;
        content: '';
        background: transparent;
        width: 10px;
        height: 10px;
        display: block; }
    .reassign-radio-group .reassign-radio-label:active .reassign-radio-filler {
      border-color: #0070EB; }
      .reassign-radio-group .reassign-radio-label:active .reassign-radio-filler:before {
        background: #0070EB; }
    .reassign-radio-group .reassign-radio-label.selected .reassign-radio-filler {
      border-color: #0070EB; }
      .reassign-radio-group .reassign-radio-label.selected .reassign-radio-filler:before {
        background: #0070EB; }
    .reassign-radio-group .reassign-radio-label.disabled {
      cursor: not-allowed; }
      .reassign-radio-group .reassign-radio-label.disabled .reassign-radio-filler {
        border-color: #CACACA; }

.reassign-dropdown {
  cursor: pointer; }

.reassign-dropdown--relative {
  position: relative; }

.reassign-dropdown.open .reassign-dropdown-menu, .reassign-dropdown.show .reassign-dropdown-menu {
  display: block;
  visibility: visible;
  opacity: 1;
  animation: .2s showFade ease-in forwards; }

.reassign-dropdown-toggle {
  display: flex;
  background: #FFF;
  padding: 8px 12px;
  border-radius: 4px; }

.reassign-dropdown-menu {
  position: absolute;
  visibility: hidden;
  display: none;
  opacity: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  top: 100%;
  width: 100%;
  min-width: 200px;
  left: auto;
  right: auto;
  z-index: 1010;
  border: 0;
  background-color: #FFF;
  background-clip: padding-box;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.175);
  -webkit-animation: .2s hideFade ease-out forwards;
  animation: .2s hideFade ease-out forwards; }

.reassign-dropdown-menu-item--disabled .reassign-dropdown-menu-item-link, .reassign-dropdown-menu-item--disabled .reassign-dropdown-menu-item-link:hover, .reassign-dropdown-menu-item--disabled .reassign-dropdown-menu-item-link:focus,
.reassign-dropdown-menu-item.disabled .reassign-dropdown-menu-item-link,
.reassign-dropdown-menu-item.disabled .reassign-dropdown-menu-item-link:hover,
.reassign-dropdown-menu-item.disabled .reassign-dropdown-menu-item-link:focus {
  color: #CACACA;
  background: transparent;
  text-decoration: none;
  cursor: not-allowed; }

.reassign-dropdown-menu-item--active .reassign-dropdown-menu-item-link, .reassign-dropdown-menu-item--active .reassign-dropdown-menu-item-link:hover, .reassign-dropdown-menu-item--active .reassign-dropdown-menu-item-link:focus,
.reassign-dropdown-menu-item.active .reassign-dropdown-menu-item-link,
.reassign-dropdown-menu-item.active .reassign-dropdown-menu-item-link:hover,
.reassign-dropdown-menu-item.active .reassign-dropdown-menu-item-link:focus {
  padding-left: 20px;
  outline: 0;
  background-color: #0058b8;
  color: #FFF;
  text-decoration: none; }

.reassign-dropdown-menu-item-link {
  outline: 0;
  display: block;
  clear: both;
  overflow: hidden;
  padding: 8px 20px;
  line-height: 1.5;
  background-color: #FFF;
  color: rgba(0, 0, 0, 0.87);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  transition: all .5s; }
  .reassign-dropdown-menu-item-link:active, .reassign-dropdown-menu-item-link:hover, .reassign-dropdown-menu-item-link:focus {
    padding-left: 28px;
    background-color: #0070EB;
    color: #FFF;
    text-decoration: none; }

.reassign-dropdown-menu--left {
  left: 0; }

.reassign-dropdown-menu--right {
  right: 0; }

.reassign-dropdown-toggle-arrow {
  margin-left: 4px;
  transition: .2s ease-in-out transform;
  color: #0070EB;
  transform-origin: center center; }

/**
 * Links
 *
 * 1) Base Styles
 * 2) Components
 *
 **/
.reassign-link-primary, a {
  transition: color 0.2s ease;
  cursor: pointer; }

/**
 * MAIN LINK TYPES
 *
 * 1) .reassign-link-primary : use this inline with reassign-text-primary
 * 2) .reassign-link-secondary : use this inline with reassign-text-secondary
/* --------------------------------------- */
.reassign-link-primary, a {
  text-decoration: none;
  color: #0070EB; }
  .reassign-link-primary:hover, a:hover, .reassign-link-primary:focus, a:focus {
    color: #0058b8; }
  .reassign-link-primary:active, a:active {
    color: #003f85; }

.reassign-dialog--xs {
  width: 368px; }

.reassign-dialog--sm {
  width: 576px; }

.reassign-dialog--md {
  width: 768px; }

.reassign-dialog--lg {
  width: 992px; }

.reassign-dialog {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  background: rgba(0, 0, 0, 0.7); }

.openDialog {
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: 0; }

.openDialog .reassign-dialog {
  display: flex !important;
  justify-content: center;
  align-items: center; }

.reassign-dialog-container {
  border-radius: 3px; }

@media (max-width: 575px) {
  .isFixed {
    position: fixed; } }

@media (max-width: 575px) {
  .reassign-dialog-container {
    -webkit-overflow-scrolling: touch; } }

.reassign-dialog-container {
  height: auto;
  background-color: #FFF;
  border-radius: 3px;
  position: relative;
  overflow: hidden; }
  @media (max-width: 575px) {
    .reassign-dialog-container {
      border-radius: 0; } }

@media (max-width: 575px) {
  .reassign-dialog-container.hasFooter {
    padding-bottom: 75px; } }

.reassign-dialog-container.hasFooter .reassign-dialog-body {
  max-height: calc(100vh - 124px); }
  @media (max-width: 575px) {
    .reassign-dialog-container.hasFooter .reassign-dialog-body {
      height: calc(100vh - 151px); } }

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .reassign-dialog-container.hasFooter .reassign-dialog-body {
    max-height: calc(100vh - 189px) !important; } }

.reassign-dialog-body {
  overflow: auto;
  padding: 0 !important;
  height: auto;
  max-height: calc(100vh - 40px); }

.reassign-dialog.fade {
  animation: get-animation-timing(default) fadeOut ease forwards; }
  .reassign-dialog.fade.show {
    animation: get-animation-timing(default) fadeIn ease forwards; }

.reassign-dialog.fly .reassign-dialog-container {
  animation: get-animation-timing(quick) flyDown ease forwards; }

.reassign-dialog.fly.show .reassign-dialog-container {
  animation: get-animation-timing(default) flyUp ease forwards; }

/**
 * .reassign-toaster
 *
 * This file contains styles for toaster system. Toasters can be used on any page to slide in from the bottom of the page
 * The intent is to use reassign-toaster as the global style for the module and then compose different
 * flavors of the reassign-toaster by layering on additional classes - think how buttons get modifiers for colors etc
 *
 **/
@keyframes flyUpBounce {
  60%, 100% {
    transform: translateY(0); }
  20% {
    transform: translateY(-20px); }
  40% {
    transform: translateY(5px); } }

.reassign-toaster {
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  color: #FFF;
  background: rgba(0, 0, 0, 0);
  border-color: rgba(0, 0, 0, 0);
  transition: background 0.2s ease, border-color 0.2s ease; }
  .reassign-toaster:hover, .reassign-toaster:focus {
    color: #FFF; }
  .reassign-toaster:hover, .reassign-toaster:focus, .reassign-toaster.focus {
    text-decoration: none; }

.reassign-toaster--primary {
  background: #3232AA; }

.reassign-toaster--secondary {
  background: #F63440; }

.reassign-toaster-wrapper {
  z-index: 1040;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  transform: translateY(100px); }

.reassign-toaster-link {
  white-space: nowrap;
  cursor: pointer; }

.bounceFlyUp {
  animation: flyUpBounce 1.8s forwards; }

html,
body,
#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0; }

#root {
  display: flex; }

.nowrap {
  white-space: nowrap; }
