.popover-toggle {
  position: relative;
  margin-top: -3px; }
  .popover-toggle:hover {
    cursor: pointer; }
  .popover-toggle .popover-toggle__button {
    display: flex;
    justify-content: center;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    padding: 0;
    border: 0;
    outline: none; }
    .popover-toggle .popover-toggle__button:focus, .popover-toggle .popover-toggle__button:hover {
      background-color: #e5e5e5; }
    .popover-toggle .popover-toggle__button.popover-toggle__button--clicked {
      background-color: #b2b2b2; }
  .popover-toggle__icon {
    width: 12px; }
    .popover-toggle__icon #Tooltips-and-Menus {
      fill: #4C4C4C; }
