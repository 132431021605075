.recommended-drivers__offer-pane-row {
  background: #f6f6f6; }

.recommended-drivers__offer-pane-col {
  padding: 0 !important;
  text-align: left !important; }

.recommended-drivers__footer {
  background-color: white;
  padding: 5px 0px 5px 12px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .recommended-drivers__footer__pagination {
    display: flex;
    align-items: center; }
    .recommended-drivers__footer__pagination__buttons {
      margin-left: 10px;
      display: flex; }
      .recommended-drivers__footer__pagination__buttons__next-page, .recommended-drivers__footer__pagination__buttons__prev-page {
        cursor: pointer; }
        .recommended-drivers__footer__pagination__buttons__next-page__disabled, .recommended-drivers__footer__pagination__buttons__prev-page__disabled {
          cursor: not-allowed; }
          .recommended-drivers__footer__pagination__buttons__next-page__disabled #caret_left, .recommended-drivers__footer__pagination__buttons__next-page__disabled #caret_right, .recommended-drivers__footer__pagination__buttons__prev-page__disabled #caret_left, .recommended-drivers__footer__pagination__buttons__prev-page__disabled #caret_right {
            fill: #ccc; }
          .recommended-drivers__footer__pagination__buttons__next-page__disabled #caret_circle_left, .recommended-drivers__footer__pagination__buttons__next-page__disabled #caret_circle_right, .recommended-drivers__footer__pagination__buttons__prev-page__disabled #caret_circle_left, .recommended-drivers__footer__pagination__buttons__prev-page__disabled #caret_circle_right {
            stroke: #ccc; }

.recommended-drivers__label {
  font: bold 14px graphik; }

.recommended-drivers__table table {
  margin-top: 11px;
  margin-bottom: 0; }
  .recommended-drivers__table table > thead > tr > th {
    text-align: right; }
    .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__driver-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__current-orders-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__availability-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__other-trips-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__FAQ-header {
      text-align: left; }
    .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__offered-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__driver-details-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__deliveries-completed-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__pickup-rate-header, .recommended-drivers__table table > thead > tr > th.recommended-drivers__table__dropoff-rate-header {
      text-align: center; }
  .recommended-drivers__table table .fade-appear, .recommended-drivers__table table .flash-appear {
    opacity: 0.01; }
  .recommended-drivers__table table .fade-enter-done, .recommended-drivers__table table .flash-enter-done {
    opacity: 1;
    transition: opacity 200ms ease-in; }
  .recommended-drivers__table table .flash-appear {
    background: rgba(0, 154, 222, 0.15); }
  .recommended-drivers__table table .flash-enter-done {
    transition: background 500ms ease-in; }

.recommended-drivers__call-out {
  margin-bottom: 0; }

.toggle-banned-drivers + .recommended-drivers__help {
  margin-top: 0;
  margin-left: 5px; }
