/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.trip-details-icon {
  position: relative;
  margin-left: -5px;
  margin-right: 10px;
  grid-area: icon;
  width: 38px;
  height: 38px; }
  .trip-details-icon .icon__icon {
    margin: 0; }
  .trip-details-icon__bg {
    width: 38px;
    height: 38px; }
    .trip-details-icon__bg svg path {
      fill: inherit;
      stroke: inherit; }
    .trip-details-icon__bg .pin-icon {
      stroke: #A6A6A8;
      fill: #FFF; }
    .trip-details-icon__bg_complete .pin-icon {
      stroke: #A6A6A8;
      fill: #4E4E52; }
  .trip-details-icon__letter {
    width: 100%;
    position: absolute;
    left: 0;
    top: 2px;
    text-align: center;
    font-family: "grubhubsans-bold";
    font-size: 13px;
    line-height: 2;
    color: #4E4E52; }
    .trip-details-icon__letter_complete {
      color: #FFF; }
  .trip-details-icon__caution {
    position: absolute;
    right: -5px;
    top: -8px; }
    .trip-details-icon__caution use {
      fill: #FF9716; }
