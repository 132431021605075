/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.recommended-drivers__table__tooltip__icon {
  position: relative;
  bottom: 1px;
  left: 5px; }
  .recommended-drivers__table__tooltip__icon #Fill {
    stroke: #0070EB; }
  .recommended-drivers__table__tooltip__icon #iconinfo-info-path {
    fill: #0070EB; }

.recommended-drivers__table__FAQ-header .recommended-drivers__table__tooltip__text {
  padding-left: 0;
  text-align: left;
  list-style-type: none; }
