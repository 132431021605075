.driver-current-trip {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center; }
  .driver-current-trip__type {
    position: absolute;
    top: 29px; }
  .driver-current-trip__pickup {
    top: 49px; }
  .driver-current-trip__icon use {
    fill: #FFF; }
