/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Lines - Variables
 *
 * This file defines line variables
 * used in components and utilities,
 * including horizontal rules and borders.
 *
 * 1) Line styles (sass map)
 * 2) Line getter function (get sass map values)
 *
 * See components/_line for line component classes.
 * See utilities/_borders for border utilities.
 *
 **/
/*------------------------------------------------------
 *
 * 1) Line styles sass map
 *
 *-----------------------------------------------------*/
/**
 * $Lines: sass-map
 *
 * This is a map of line styles
 * grouped by modification.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Line getter function
 *
 *-----------------------------------------------------*/
/**
 * get-line: Function
 *
 * @param $type
 * @param $variation (optional)
 *
 * This function takes a line style variation type from $Lines
 * and returns the appropriate value.
 * If no variation parameter is provided, the function
 * will return the base value.
 *
 * ie. get-line-width(thick) => 3px
 *     get-line-color(dark) => RGBA(...)
 **/
.reassign-trip-dialog {
  position: absolute; }
  .reassign-trip-dialog .reassign-dropdown-toggle {
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .reassign-trip-dialog .reassign-modal-body {
    overflow: visible; }
  .reassign-trip-dialog .reassign-modal-footer {
    border-top: 0;
    padding: 0 20px 20px; }
  .reassign-trip-dialog__header {
    margin-bottom: 30px; }
  .reassign-trip-dialog__footer {
    display: flex;
    width: 100%; }
  .reassign-trip-dialog__button {
    padding-left: 40px !important;
    padding-right: 40px !important; }
    .reassign-trip-dialog__button:first-of-type {
      margin-right: 15px; }
  .reassign-trip-dialog__reason--withPenalty {
    color: #F63440; }
  .reassign-trip-dialog__comment {
    width: 70%;
    height: 100px; }
  .reassign-trip-dialog__optional {
    color: gray; }
  .reassign-trip-dialog__add-template-btn {
    margin: 10px 0; }
