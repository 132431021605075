/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.recommended-drivers-list-row button {
  padding: 0; }

.recommended-drivers-list-row__button.reassign-btn {
  white-space: normal;
  text-align: left; }

.recommended-drivers-list-row__availability, .recommended-drivers-list-row__current-orders {
  text-align: left; }

.recommended-drivers-list-row__distance, .recommended-drivers-list-row__acceptance-rate, .recommended-drivers-list-row__catering-eligible, .recommended-drivers-list-row__last-seen {
  text-align: right !important; }

.recommended-drivers-list-row__driver-details, .recommended-drivers-list-row__offered, .recommended-drivers-list-row__deliveries-completed, .recommended-drivers-list-row__pickup-rate, .recommended-drivers-list-row__dropoff-rate, .recommended-drivers-list-row__offer-to-accept-p90 {
  text-align: center !important; }

.recommended-drivers-list-row__offered {
  width: 120px; }

.recommended-drivers-list-row:hover, .recommended-drivers-list-row--selected {
  background-color: #F6F6F6; }

.recommended-drivers-list-row--hovered {
  background-color: #E2F3F9; }

.recommended-drivers-list-row__reassign {
  background-color: #d8d4eb;
  color: #4329A3; }
  .recommended-drivers-list-row__reassign td, .recommended-drivers-list-row__reassign:hover {
    cursor: default;
    background-color: #d8d4eb !important; }

table.data-table > tbody > tr.recommended-drivers-list-row--reassign-eligible > td {
  border-bottom: 2px solid #cacaca;
  padding: 12px 12px 24px; }
