.driver-search-suggested-driver {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background: white; }
  .driver-search-suggested-driver:hover {
    background: #F6F6F6; }
