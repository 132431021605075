.auto-assign-status {
  margin-left: 16px;
  padding: 8px 12px;
  border-radius: 4px;
  height: 30px;
  line-height: 14px; }
  .auto-assign-status--on {
    color: gray;
    background-color: #CACACA; }
  .auto-assign-status--off {
    font-weight: bold;
    color: white;
    background-color: #ED0C36; }
