.invalid-id-warning {
  background-color: #F6F6F6;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .invalid-id-warning__image {
    display: block; }
  .invalid-id-warning__content {
    margin-top: 15px;
    font-size: 16px; }
