/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Dimension - Variables
 *
 * Dimension Helper Methods
 * Dimension Constants
 * Dimension SCSS Map
 * Dimension Getter Function (get SCSS map values)
 *
 **/
/*------------------------------------------------------
 * PrivateVariables
 *-----------------------------------------------------*/
/*------------------------------------------------------
 * Constants
 *-----------------------------------------------------*/
/*------------------------------------------------------
 * SCSS Maps
 *-----------------------------------------------------*/
/*------------------------------------------------------
 * Getter Function
 *-----------------------------------------------------*/
/**
 * Dimension - Mixins
 *
 * The following mixin is concerned only with helping apply shadows.
 *
 * Usage:
 *
 * box-shadow(2) <- applies a bottom directioned shadow
 * box-shadow(2, left) <- applies a left directioned shadow
 * box-shadow-reinforced(1, top) <- applies shadow with a reinforcing border.
 *
 **/
.weather-alert-modal .weather-alert-modal-footer {
  padding: 14px 16px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); }
  .weather-alert-modal .weather-alert-modal-footer .weather-alert-modal-button {
    height: 44px;
    width: 144px;
    line-height: normal; }

.weather-alert-modal .weather-alert-info {
  padding-bottom: 5px; }
