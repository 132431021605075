.reassign-toaster-wrapper.reassign-toaster-wrapper--top {
  padding: 0;
  top: 0;
  bottom: unset;
  transform: translateY(0); }

.reassign-toaster.reassign-toaster--success {
  background: #00AB11; }

.reassign-toaster.reassign-toaster--warning {
  background: #ED0C36; }
