.manual-restaurant-confirmation {
  padding-left: 16px;
  margin-top: 20px; }
  .manual-restaurant-confirmation .checkbox__box {
    min-width: 20px; }
  .manual-restaurant-confirmation__icon {
    position: relative;
    top: -1px; }
    .manual-restaurant-confirmation__icon--check #iconcheck-boundingbox {
      fill: #FFFFFF; }
    .manual-restaurant-confirmation__icon--check #mask-3 {
      fill: white; }
    .manual-restaurant-confirmation__icon--check #check + g {
      fill: #13AA37; }
