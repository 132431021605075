/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Borders - Variables
 *
 * This file defines border radius variables
 * used in components and utilities.
 *
 * 1) Border radius sizes(sass map)
 * 2) @function get-border-radius
 * 3) @mixin round-side
 *
 **/
/*------------------------------------------------------
 *
 * 1) Border radius styles
 *
 *-----------------------------------------------------*/
/**
 * $BorderRadius: sass-map
 *
 * Map of border radius sizes.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Border radius getter function
 *
 *-----------------------------------------------------*/
/**
 * get-border-radius: Function
 *
 * @param $size (optional)
 *
 * This function takes a border radius size
 * and returns the pixel value for that size.
 * If no param is specified, the base radius is returned.
 *
 * ie. get-border-radius(large) => 3px
 *
 **/
.trip-details__row {
  position: relative;
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 22fr 20fr 18fr 18fr 18fr 6fr;
  grid-template-areas: "type confirmation time distance address options";
  grid-gap: 1px 1px;
  border-bottom: 1px solid #E4E4E5;
  background-color: #FFF; }
  .trip-details__row--dragging {
    outline: none;
    background-color: #FFF;
    border-left: 2px solid #008280; }
  .trip-details__row__address {
    grid-area: address; }
  .trip-details__row__options__content {
    display: flex;
    align-items: center;
    justify-content: center; }
  .trip-details__row__remove {
    cursor: pointer; }
  .trip-details__row__drag-icon {
    position: absolute;
    top: 0;
    right: 0; }
    .trip-details__row__drag-icon circle {
      fill: #000; }
  .trip-details__row__corporate-diner {
    display: block;
    margin-top: 3px;
    padding: 3px 5px;
    border-radius: 3px;
    background: #E6ECEF; }
    .trip-details__row__corporate-diner__icon {
      position: relative;
      top: -1px; }
      .trip-details__row__corporate-diner__icon #Tag-Exploration {
        fill: #0B4666; }
  .trip-details__row:last-of-type {
    padding-bottom: 19px; }

.trip-details__row__completed path {
  fill: #02A92B; }

.trip-details__row__type {
  grid-area: type;
  display: flex; }

.trip-details__row__time {
  grid-area: time; }

.trip-details__row__distance {
  grid-area: distance; }

.trip-details__row__options {
  grid-area: options;
  align-self: center; }

.trip-details__row__original-pickup-time {
  grid-column-start: 3;
  margin-top: 8px; }
