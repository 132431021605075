/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Text - Variables
 *
 * This file defines text variables
 * used in components and utilities.
 *
 **/
/**
 * get-text-color: Function
 *
 **/
.weather-alerts {
  position: relative;
  top: -3px;
  margin-left: 12px; }
  .weather-alerts__error {
    color: #FF9100; }
  .weather-alerts__icon {
    display: flex;
    height: 32px;
    align-items: center; }
    .weather-alerts__icon path {
      fill: #FF9100; }
  .weather-alerts__button {
    display: flex;
    height: 32px;
    margin: 0;
    padding: 0 10px;
    background-color: #FFF;
    border: 1px solid #FFF;
    border-radius: 27px;
    font-size: 14px;
    line-height: 32px; }
    .weather-alerts__button:hover, .weather-alerts__button-hover {
      cursor: pointer;
      border: 1px solid #4E4E52; }
  .weather-alerts__extra {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    white-space: pre;
    max-height: 306px;
    min-width: 300px;
    overflow: auto;
    background: #FFF;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5); }
  .weather-alerts__alert {
    padding: 12px 12px 3px 12px;
    border-bottom: 1px solid #C9C9C9; }
    .weather-alerts__alert-name {
      font-size: 15px; }
    .weather-alerts__alert-time {
      padding-top: 8px;
      color: #7F7F7F;
      font-size: 15px; }
  .weather-alerts .alert-btn {
    padding: 8px 0;
    border: none; }
    .weather-alerts .alert-btn:hover, .weather-alerts .alert-btn:focus {
      color: #0070EB;
      background-color: transparent;
      border-color: transparent; }
