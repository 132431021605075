/**
 * nth-root: Function
 *
 * @param $number
 * @param $root
 *
 * Returns the nth-root of a number
 *
 *
 * ie. ratio(font, desktop)
 * @debug nth-root(16, 2) == 4;
 *
 **/
/**
 * ratio: Function
 *
 * @param $scale
 * @param $device
 *
 * This function takes a type of scale (lineheight || font)
 * and a device type (mobile || desktop)
 * and returns the exponential ratio for the font scale.
 *
 * ie. ratio(font, desktop)
 **/
/**
 * Framework grid generation
 **/
/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Grid Variables
 **/
/**
* get-breakpoint: Function
*
* @param $bp
*
* This function takes a breakpoint value (xs, sm, etc)
* and returns the pixel value for that breakpoint
*
* ie. get-breakpoint(xl) => 1200px
**/
/**
 * Fonts Variables
 *
 * This file defines font variables
 * that are used by type based patterns
 * such as headings and copy (see variables/_typography.scss).
 *
 * 1) Font constants
 * 2) Font styles sass map
 * 3) Font scale functions
 * 4) Font mixin
 *
 **/
/*------------------------------------------------------
 *
 * 1) Font constants
 * adjustments, sizes, smaller, base and max
 *
 * These values are used to compute font scale ratios
 * for each font.
 *
 *-----------------------------------------------------*/
/**
 * Font adjustments
 *
 * These constants are used to normalize the height difference
 * of lowercase letters between fonts.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Font style variables sass map
 *
 *-----------------------------------------------------*/
/**
 * $FONT_DATAMAP: sass-map
 *
 * This is a map of all font related variables.
 *
 * Description of each font variable group:
 *
 * face: the name used to declare the font.
 *
 * adjustments: the font face's x-height adjustment value.
 *
 * scale: these values are used to determine the overall scaling ratio
 * for the font scale for each font.
 *
 *   - numLarger: the number of larger sizes the font scale calculates the ratio with.
 *   - size: the size's exponential degree with which to apply the font scale ratio.
 *   - smaller: smaller size overrides.
 *   - base: base maximum font sizes (px) and line heights (ratio) for desktop and mobile.
 *   - max: maximum font sizes (px) and line heights (ratio) for desktop and mobile.
 *
 * Note that the base size is NOT the minimum font size,
 * rather the one optimized for comfortable reading.
 *
 **/
/*------------------------------------------------------
 *
 * 4) font Mixin
 *
 * @param $font
 * @param $size
 *
 * Use this function to create a responsive font style.
 *
 * Ex:
 * h1 {
 *   @include create-font(grubhubsans-bold, larger5)
 * }
 *
 *-----------------------------------------------------*/
/*------------------------------------------------------
 *
 * 3) Font scale functions and mixins
 *
 *-----------------------------------------------------*/
/**
 * font-size: Function
 *
 * @param $font
 * @param $size
 * @param $device
 *
 * This function takes a font name, size and device type
 * and returns the computed font size (px).
 *
 * ie. font-size(graphik, base, desktop)
 **/
/**
 * line-height: Function
 *
 * @param $font
 * @param $size
 * @param $device
 *
 * This function takes a font name, size and device type
 * and returns the computed line height ratio.
 *
 * ie. line-height(graphik, base, desktop)
 **/
/*
 * get-font-face: Function
 *
 * @param $family
 * @param $weight (optional)
 *
 * This function takes a font family and optional weight
 * and returns the font face name.
 *
 * ie. get-font-face(graphik, bold) => graphik-bold
 **/
/**
 * Borders - Variables
 *
 * This file defines border radius variables
 * used in components and utilities.
 *
 * 1) Border radius sizes(sass map)
 * 2) @function get-border-radius
 * 3) @mixin round-side
 *
 **/
/*------------------------------------------------------
 *
 * 1) Border radius styles
 *
 *-----------------------------------------------------*/
/**
 * $BorderRadius: sass-map
 *
 * Map of border radius sizes.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Border radius getter function
 *
 *-----------------------------------------------------*/
/**
 * get-border-radius: Function
 *
 * @param $size (optional)
 *
 * This function takes a border radius size
 * and returns the pixel value for that size.
 * If no param is specified, the base radius is returned.
 *
 * ie. get-border-radius(large) => 3px
 *
 **/
.marker-label {
  font-family: "graphik";
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  background-color: #FFF;
  text-align: center;
  padding: 2px 5px;
  border-radius: 3px; }
  @media (min-width: 768px) {
    .marker-label {
      font-size: 14px;
      line-height: 1.45; } }
  .marker-label--selected {
    font-family: "graphik-bold";
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .marker-label--selected {
        font-size: 14px;
        line-height: 1.45; } }
  .marker-label-secondary {
    font-family: "graphik";
    font-weight: normal;
    font-size: 14px;
    line-height: 1.4; }
    @media (min-width: 768px) {
      .marker-label-secondary {
        font-size: 14px;
        line-height: 1.45; } }
