/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Lines - Variables
 *
 * This file defines line variables
 * used in components and utilities,
 * including horizontal rules and borders.
 *
 * 1) Line styles (sass map)
 * 2) Line getter function (get sass map values)
 *
 * See components/_line for line component classes.
 * See utilities/_borders for border utilities.
 *
 **/
/*------------------------------------------------------
 *
 * 1) Line styles sass map
 *
 *-----------------------------------------------------*/
/**
 * $Lines: sass-map
 *
 * This is a map of line styles
 * grouped by modification.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Line getter function
 *
 *-----------------------------------------------------*/
/**
 * get-line: Function
 *
 * @param $type
 * @param $variation (optional)
 *
 * This function takes a line style variation type from $Lines
 * and returns the appropriate value.
 * If no variation parameter is provided, the function
 * will return the base value.
 *
 * ie. get-line-width(thick) => 3px
 *     get-line-color(dark) => RGBA(...)
 **/
/**
 * Borders - Variables
 *
 * This file defines border radius variables
 * used in components and utilities.
 *
 * 1) Border radius sizes(sass map)
 * 2) @function get-border-radius
 * 3) @mixin round-side
 *
 **/
/*------------------------------------------------------
 *
 * 1) Border radius styles
 *
 *-----------------------------------------------------*/
/**
 * $BorderRadius: sass-map
 *
 * Map of border radius sizes.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Border radius getter function
 *
 *-----------------------------------------------------*/
/**
 * get-border-radius: Function
 *
 * @param $size (optional)
 *
 * This function takes a border radius size
 * and returns the pixel value for that size.
 * If no param is specified, the base radius is returned.
 *
 * ie. get-border-radius(large) => 3px
 *
 **/
/**
 * Dimension - Mixins
 *
 * The following mixin is concerned only with helping apply shadows.
 *
 * Usage:
 *
 * box-shadow(2) <- applies a bottom directioned shadow
 * box-shadow(2, left) <- applies a left directioned shadow
 * box-shadow-reinforced(1, top) <- applies shadow with a reinforcing border.
 *
 **/
.popover {
  background: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: get-shadow(1, );
  border-bottom: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  z-index: 1; }
  .popover--left {
    left: 0; }
  .popover--right {
    right: 0; }
  .popover__option {
    min-width: 100%;
    padding: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 15.4px;
    width: max-content; }
    .popover__option--clickable {
      color: #1686C3;
      cursor: pointer; }
  .popover__button {
    width: 100% !important;
    text-align: left !important; }
  .popover__label {
    padding: 14px 20px;
    margin-bottom: 0;
    font-size: 14px;
    cursor: default; }
