/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.call-out {
  display: block;
  padding: 16px 46px 16px 64px;
  margin: 0 0 16px;
  border-top: 4px solid #EFEFEF;
  background: #F6F6F6;
  border-radius: 0 0 3px 3px;
  position: relative; }
  .call-out--success {
    border-color: #00AB11;
    background: #A4E2B2; }
  .call-out--caution {
    border-color: #FF9100;
    background: #FFD7A7; }
  .call-out--warning {
    border-color: #ED0C36;
    background: #FFB3BC; }
  .call-out__icon {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%); }
  .call-out__title {
    font-family: 'grubhubsans-bold';
    font-size: 17.7px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0; }
  .call-out__description {
    font-family: 'graphik';
    font-size: 16.1px;
    color: rgba(0, 0, 0, 0.87);
    margin: 4px 0 0; }
  .call-out__close {
    position: absolute;
    top: 16px;
    right: 16px;
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
    font-weight: bold; }
