.app-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column; }
  .app-wrapper__localhost-production-warning {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    background: orange;
    color: white;
    font-weight: bold; }
