.settings-page {
  width: 100%;
  margin: 18px; }
  .settings-page__table__local pre {
    width: 100px; }
  .settings-page__table__default pre, .settings-page__table__environment pre {
    display: block;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.settings-page__edit-icon {
  cursor: pointer; }
