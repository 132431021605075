.reassign-radio-group {
  display: flex; }
  .reassign-radio-group .reassign-radio-label {
    margin-bottom: 12px; }
    .reassign-radio-group .reassign-radio-label:last-child {
      margin-bottom: 0; }
  .reassign-radio-group--vertical {
    flex-direction: column; }
  .reassign-radio-group--horizontal {
    flex-direction: row;
    flex-wrap: wrap; }
    .reassign-radio-group--horizontal .reassign-radio-label {
      margin-right: 12px; }
      .reassign-radio-group--horizontal .reassign-radio-label:last-child {
        margin-right: 0; }
