/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * Borders - Variables
 *
 * This file defines border radius variables
 * used in components and utilities.
 *
 * 1) Border radius sizes(sass map)
 * 2) @function get-border-radius
 * 3) @mixin round-side
 *
 **/
/*------------------------------------------------------
 *
 * 1) Border radius styles
 *
 *-----------------------------------------------------*/
/**
 * $BorderRadius: sass-map
 *
 * Map of border radius sizes.
 *
 **/
/*------------------------------------------------------
 *
 * 2) Border radius getter function
 *
 *-----------------------------------------------------*/
/**
 * get-border-radius: Function
 *
 * @param $size (optional)
 *
 * This function takes a border radius size
 * and returns the pixel value for that size.
 * If no param is specified, the base radius is returned.
 *
 * ie. get-border-radius(large) => 3px
 *
 **/
table.data-table {
  width: 100%;
  max-width: 100%;
  border-radius: 3px;
  border-spacing: 0;
  margin: 20px 0 0;
  background-color: #FFF; }
  table.data-table > thead > tr,
  table.data-table > tbody > tr,
  table.data-table > tfoot > tr {
    cursor: pointer; }
    table.data-table > thead > tr > th,
    table.data-table > thead > tr > td,
    table.data-table > tbody > tr > th,
    table.data-table > tbody > tr > td,
    table.data-table > tfoot > tr > th,
    table.data-table > tfoot > tr > td {
      padding: 12px 12px;
      line-height: 1.5;
      vertical-align: top;
      text-align: left; }
    table.data-table > thead > tr > th,
    table.data-table > tbody > tr > th,
    table.data-table > tfoot > tr > th {
      font-weight: normal;
      border-bottom: get-line-width(thin) solid get-line-color(dark);
      vertical-align: bottom; }
    table.data-table > thead > tr > td,
    table.data-table > tbody > tr > td,
    table.data-table > tfoot > tr > td {
      white-space: nowrap;
      text-overflow: ellipsis;
      border-bottom: get-line-width(thin) solid get-line-color(light); }
  table.data-table > tbody > tr:hover > td {
    background-color: #F6F6F6; }
  table.data-table > caption + thead > tr:first-child > th,
  table.data-table > caption + thead > tr:first-child > td,
  table.data-table > colgroup + thead > tr:first-child > th,
  table.data-table > colgroup + thead > tr:first-child > td,
  table.data-table > thead:first-child > tr:first-child > th,
  table.data-table > thead:first-child > tr:first-child > td {
    border-top: 0; }
