.map-box-gl {
  background-color: #0e0e0e; }
  .map-box-gl__error {
    display: flex;
    height: 100%;
    flex-direction: column;
    background: #0e0e0e;
    color: #fff; }
    .map-box-gl__error h3, .map-box-gl__error h4 {
      padding-top: 32px;
      text-align: center; }
