.error-broken-egg {
  background-color: #F6F6F6; }
  .error-broken-egg__text {
    max-width: 40vw; }
  .error-broken-egg__title {
    margin-top: 32px; }
  .error-broken-egg__message {
    margin: 10px 0; }
  .error-broken-egg__close-button {
    margin: 16px 0; }
  .error-broken-egg__code {
    font-size: 12px; }
