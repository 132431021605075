.mini-loader {
  display: inline-block;
  border: 3px solid #CCE2FB;
  border-top: 3px solid #0070EB;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
  margin: 4px; }

@keyframes spin {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
