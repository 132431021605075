.comment-modal__comment {
  margin-top: 20px;
  width: 100%;
  height: 100px; }

.comment-modal__add-template-btn {
  margin-top: 10px; }

.comment-modal__optional {
  color: gray; }

.comment-modal__footer {
  border-top: 0;
  padding: 0 20px 20px; }

.comment-modal__button {
  width: 100px; }
  .comment-modal__button:first-of-type {
    margin-right: 15px; }
