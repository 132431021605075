.remaining-badge {
  font-size: 12px;
  margin-right: 6px;
  display: inline-block;
  width: 18px;
  height: 18px;
  line-height: 17px;
  background: #fff;
  border: 1px solid #98989c;
  border-radius: 18px;
  color: #202024;
  text-align: center; }
