.manual-restaurant-confirmation-modal .btn-group {
  display: flex;
  justify-content: center;
  margin-top: 16px; }

.manual-restaurant-confirmation-modal__comment {
  width: 100%;
  height: 150px; }

.manual-restaurant-confirmation-modal__optional {
  color: gray; }

.manual-restaurant-confirmation-modal__add-template-btn {
  margin-bottom: 15px; }

.manual-restaurant-confirmation-modal__footer-button {
  width: 120px; }
  .manual-restaurant-confirmation-modal__footer-button:first-child {
    margin-right: 5px; }
