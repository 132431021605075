.activity-log .activity-log__timestamp-header,
.activity-log .activity-log__timestamp {
  text-align: right; }

.activity-log .icon__icon-orderPlaced g {
  fill: #3C3C3C; }

.activity-log .icon__icon-orderConfirmed #icon_stamp_path {
  fill: #4E4E52; }

.activity-log .icon__icon-orderCanceled g {
  fill: #DC2F39; }

.activity-log .icon__icon-offerCreated #icon_hand_star_path {
  fill: #4E4E52; }

.activity-log .icon__icon-offerAccepted path {
  fill: #02A92B; }

.activity-log .icon__icon-offerReaped use {
  fill: #FF9716; }

.activity-log .icon__icon-grubhub #mask2 {
  fill: white; }

.activity-log .icon__icon-grubhub #icon_gh > use {
  fill: #3C3C3C; }

.activity-log .icon__icon-restaurant #iconutensils-boundingbox {
  fill: #FFFFFF; }

.activity-log .icon__icon-restaurant #mask-2 {
  fill: white; }

.activity-log .icon__icon-restaurant #icon_utensils > use {
  fill: #3C3C3C; }

.activity-log .icon__icon-driver #Line_Icons {
  fill: #3C3C3C; }

.activity-log .icon__icon-diner #Filled_Icons {
  fill: #3C3C3C; }
