.input {
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0; }
  .input--valid .input__label {
    color: #13AA37; }
  .input--valid .input__input {
    border-color: #13AA37; }
  .input--valid .input__help-text {
    color: #13AA37; }
  .input--invalid .input__label {
    color: #FB6100; }
  .input--invalid .input__input {
    border-color: #FB6100; }
  .input--invalid .input__help-text {
    color: #FB6100; }

.input__input-container {
  flex: 1;
  position: relative; }

.input__label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 8px;
  display: block; }

.input__input {
  background: #FFFFFF;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 0 16px 0;
  padding: 0 16px;
  height: 44px;
  width: 100%;
  box-sizing: border-box;
  outline: none; }
  .input__input:focus {
    border-color: #0070EB; }
  .input__input:disabled {
    color: rgba(0, 0, 0, 0.35);
    border-color: rgba(0, 0, 0, 0.1);
    cursor: not-allowed; }
  .input__input::placeholder {
    color: rgba(0, 0, 0, 0.5); }
  .input__input--has-help-text {
    margin-bottom: 0; }
  .input__input--small {
    height: 36px;
    padding: 0 12px;
    font-size: 12px; }
  .input__input--large {
    height: 48px;
    font-size: 16.2px; }

.input__suffix {
  position: absolute;
  top: 0;
  right: 16px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center; }

.input__help-text {
  margin: 4px 0 16px 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5); }
