/**
 * Colors - Variables
 *
 * Color Constants
 * Color Sass maps
 * Color getter functions (get sass map values)
 * Sass to Js
 *
 **/
/*------------------------------------------------------
 * Color Constants
 *-----------------------------------------------------*/
/**
 * $BRAND_COLORS: sass-map
 *
 * This is a map of brand specific
 * color variations.
 **/
/**
* get-brand-color: Function
*
* @param $palette
* @param $brand
*
* This function takes a palette value (ie. Interactive)
* and a brand value ($BRAND) set in seamless.scss, grubhub.scss, etc.
* and returns the color constant for the brand.
*
* ie. get-color(interactive) => #HEXVAL
**/
/**
 * $COLOR_DATAMAP: sass-map
 *
 * This is a map of color palette values.
 *
 * ex: success => COLOR_SPEARMINT
 **/
/**
* get-color: Function
*
* @param $palette
*
* This function takes a palette value (ie. Interactive)
* and tone value (base by default) and returns the
* shade from a color palette in the $Colors sass map.
*
* ie. get-color(interactive) => #HEXVAL
**/
.file-input {
  margin: 15px 0; }
  .file-input__container {
    display: flex;
    align-items: center; }
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1; }
    .file-input__input:disabled + .file-input__label {
      background: #C9C9C9; }
  .file-input__clear {
    margin-left: 10px;
    background: none;
    border: 0;
    color: #0078B4;
    cursor: pointer;
    font-size: 14px; }
  .file-input__warning {
    margin-left: 10px;
    color: #F63440; }
  .file-input__selected-files {
    margin-top: 10px;
    width: 60%; }
    .file-input__selected-files__file {
      margin: 5px 0 0 0;
      padding: 2px 10px;
      background: #E5E5E5;
      border-radius: 3px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
